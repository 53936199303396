import { SearchResultComponent } from "./../modules/vr-search/components/search-result/search-result.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreRoutingModule } from "./core-routing.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AmplifyAngularModule, AmplifyService } from "aws-amplify-angular";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "../shared/shared.module";
//import { DeviceDetectorModule } from 'ngx-device-detector';
import { AuthenticationService } from "./services/authentication.service";

import { AuthFormComponent } from "./components/authentication/auth-form/auth-form.component";
import { SignUpComponent } from "./components/authentication/sign-up/sign-up.component";
import { SignInComponent } from "./components/authentication/sign-in/sign-in.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { VideoRolaComponent } from "./components/video-rola/video-rola.component";
import { HomeComponent } from "./components/home/home.component";
import { ProfileComponent } from "./components/user/profile/profile.component";
import { SignInVrComponent } from "./components/authentication/sign-in-vr/sign-in-vr.component";
import { BioComponent } from "./components/user/profile/bio/bio.component";
import { PrivacyComponent } from "./components/user/privacy/privacy.component";
import { TermsComponent } from "./components/user/terms/terms.component";
import { SupportComponent } from "./components/support/support.component";
import { AuthGuard } from "../guards";
import { IconPipe } from "./pipes/icon.pipe";
import { AppleSigninModule } from "ng8-apple-signin";
import { ViewerComponent } from "./components/viewer/viewer.component";
// import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
// import { AngularFireModule } from '@angular/fire';
// import { environment } from './../../environments/environment';
@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SidenavComponent,
    VideoRolaComponent,
    HomeComponent,
    SignInComponent,
    SignUpComponent,
    AuthFormComponent,
    ProfileComponent,
    SignInVrComponent,
    BioComponent,
    PrivacyComponent,
    TermsComponent,
    SupportComponent,
    IconPipe,
    ViewerComponent,
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    AmplifyAngularModule,
    FormsModule,
    NgbModule,
    SharedModule,
    //DeviceDetectorModule.forRoot(),
    AppleSigninModule,
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAnalyticsModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    SidenavComponent,
    VideoRolaComponent,
  ],
  providers: [
    AuthenticationService,
    AmplifyService,
    AuthGuard,
    SearchResultComponent,
  ],
  entryComponents: [ViewerComponent],
})
export class CoreModule {}
