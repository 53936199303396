import { ISession } from "./../../models/ISession";
import { IUser } from "./../../models/IUser";
import { AmplifyService } from "aws-amplify-angular";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import API from "../../configs/ApisConfig";
import { IPreferences } from "../../models";
import { LoadingBarService } from "@ngx-loading-bar/core";
import Swal, { SweetAlertOptions } from "sweetalert2";

const apigClientFactory = require("aws-api-gateway-client").default;

/**
 * This class provides the NameList service with methods to read names and add names.
 */
@Injectable()
export class AuthenticationService {
  private endpoint =
    "https://8dcaeosk1a.execute-api.us-east-1.amazonaws.com/development/aldea-users-api/users";
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  public currentAuthUserSubject: BehaviorSubject<IUser>;
  public currentUserAuth: Observable<IUser>;
  public userIsLoggedIn: Boolean = false;
  public get currentUserAuthValue(): IUser {
    return this.currentAuthUserSubject.value;
  }

  /**
   * Creates a new NameListService with the injected Http.
   * @param http - The injected Http.
   */
  constructor(
    private http: HttpClient,
    private amplifyService: AmplifyService,
    private loadingBar: LoadingBarService
  ) {
    this.currentAuthUserSubject = new BehaviorSubject<IUser>(
      JSON.parse(localStorage.getItem("CUA"))
    );
    this.currentUserAuth = this.currentAuthUserSubject.asObservable();
  }

  async logOut(): Promise<any> {
    return this.amplifyService
      .auth()
      .signOut()
      .then(() => {
        this.amplifyService.authState().next(null);
        return true;
      })
      .catch((err) => {
        return false;
      });
  }

  async logIn(email: string, password: string) {
    this.amplifyService
      .auth()
      .signIn(email, password)
      .then((res) => {
        this.amplifyService.authState().next(res);
        console.log("data from login ");
        console.log(res);
        return res;
      })
      .catch((e) => {
        return e;
      });
  }

  async loginSocial(
    provider: string,
    token: string,
    identity_id: string,
    expiresIn: number,
    user: any
  ) {
    this.amplifyService
      .auth()
      .federatedSignIn(
        provider,
        {
          token,
          expires_at: expiresIn * 1000 + new Date().getTime(),
        },
        user
      )
      .then((cred) => {
        console.log("Credenciales: ");
        console.log(cred);
        return this.amplifyService.auth().currentAuthenticatedUser();
      })
      .then((user_) => {
        console.log(user_);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  /**
   *  OK
   * Returns an Observable for the HTTP GET request for the JSON resource.
   * @return The Observable for the HTTP request.
   */
  private extractData(res: Response) {
    const body = res;
    console.log(body);
    return body || {};
  }

  getUserInformation(id: string, extra: string = "null"): Observable<any> {
    return this.http
      .get(this.endpoint + "/" + id + "?email=" + extra)
      .pipe(map(this.extractData));
  }

  public checkTerms(id: string): Observable<any> {
    return this.http
      .get(API.dev.url + API.dev.TemplatePaths.TERMS_VERIFICATION + "/" + id)
      .pipe(map(this.extractData));
  }

  public updateTermsAcceptance(
    id: string,
    policyId: string,
    type: string
  ): Observable<any> {
    return this.http
      .put(API.dev.url + API.dev.TemplatePaths.TERMS_VERIFICATION, {
        user_id: id,
        document_type: type,
        policy_id: policyId,
      })
      .pipe(map(this.extractData));
  }

  addUser(user: IUser): Observable<any> {
    return this.http.post<any>(
      this.endpoint,
      { userAttributes: JSON.stringify(user) },
      this.httpOptions
    );
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  /**
   * OK
   */
  async signUp(name: string, email: string, password: string) {
    return this.amplifyService
      .auth()
      .signUp({
        username: email,
        password: password,
        attributes: {
          name: name,
          email: email,
        },
      })
      .then((data) => {
        console.log(data);
        return data;
      })
      .catch((error) => {
        return error;
      });
  }

  async resendConfirmationEmail(email: string): Promise<any> {
    return this.amplifyService.auth().resendSignUp(email);
  }

  async cognitoSignUp(
    name: string,
    email: string,
    password: string
  ): Promise<any> {
    return this.amplifyService.auth().signUp({
      username: email,
      password: password,
      attributes: {
        name: name,
        email: email,
      },
    });
  }

  /**
   *  OK
   */
  logout() {
    // Verificar si cuando se inicia con red social, el amplify service elimina credenciales.

    const user_id = this.getCurrentUserInformation().user_id;
    const session_id = this.getCurrentSession().id;

    this.updateSession(user_id, session_id).subscribe(
      (success) => {
        if (this.getCurrentProviderAuth() === "VR-A") {
          this.amplifyService
            .auth()
            .signOut()
            .then((r) => {
              this.deleteInfo();
              this.userIsLoggedIn = false;
            })
            .catch((e) => {});
        } else if (this.getCurrentProviderAuth() === "SL-A") {
          this.deleteInfo();
        } else if (this.getCurrentProviderAuth() === "VR-TVP") {
          this.deleteInfo();
        }
      },
      (err) => {}
    );
  }

  /**
   * OK
   */
  public deleteInfo(): void {
    this.removeCurrentUserInformation(); // Eliminar informacion del usuario
    this.removeCurrentProviderAutg(); // Eliminar informacion del porveedor de auth
    this.currentAuthUserSubject.next(null); // Actualizar la sesion
    this.removeCurrentSession(); // Elimina datos de la sesson
  }

  public async deleteAccount(id: string, email: string) {
    const result = await this.http
      .delete(this.endpoint + "/" + id + "/" + email)
      .toPromise();
    console.log(result);
  }

  /**
   * OK
   */
  setCurrentProviderAuth(provider: string) {
    this.userIsLoggedIn = true;
    localStorage.setItem("CP", provider);
  }
  /**
   * OK
   */
  getCurrentProviderAuth() {
    return localStorage.getItem("CP");
  }
  /**
   * OK
   */
  removeCurrentProviderAutg() {
    localStorage.removeItem("CP");
  }
  /**
   * OK
   */
  setCurrentUserInformation(user: IUser) {
    this.setPreferences({ isShowMainAd: false });
    localStorage.setItem("CUI", JSON.stringify(user));
    this.currentAuthUserSubject.next(user);
  }
  /**
   * OK
   */
  getCurrentUserInformation(): IUser {
    return JSON.parse(localStorage.getItem("CUI"));
  }
  /**
   * OK
   */
  removeCurrentUserInformation() {
    localStorage.removeItem("CUI");
    this.setPreferences({ isShowMainAd: true });
  }

  updateUserAttribute(atr: string, value: string) {
    const suerA = this.getCurrentUserInformation();
    suerA[atr] = value;
  }

  /* Sessions */

  /**
   * OK
   */
  setCurrentSession(user: ISession) {
    localStorage.setItem("SESSION", JSON.stringify(user));
  }
  /**
   * OK
   */
  getCurrentSession(): ISession {
    return JSON.parse(localStorage.getItem("SESSION"));
  }
  /**
   * OK
   */
  removeCurrentSession() {
    localStorage.removeItem("SESSION");
  }

  /**
   *  Verifica si se puede crear una nueva session
   */
  checkSession(user: string, user_type: string): Observable<any> {
    const config = { invokeUrl: API.dev.url };
    const apigClient = apigClientFactory.newClient(config);
    const additionalParams = {
      headers: { "Content-Type": "application/json" },
      queryParams: {},
    };
    return Observable.create((obs) => {
      apigClient
        .invokeApi(
          {},
          API.dev.TemplatePaths.SESSIONS + user + "?login_type=" + user_type,
          "GET",
          additionalParams,
          {}
        )
        .then(function (result) {
          obs.next(result);
        })
        .catch(function (result) {
          obs.error(result);
        });
    });
  }

  /**
   *  Verifica si se puede crear una nueva session
   */
  closeSelectedSession(user_id: string, session_id: string): Observable<any> {
    return this.http.delete<any>(
      API.dev.url +
        API.dev.TemplatePaths.SESSIONS_CHECK +
        user_id +
        "/" +
        session_id,
      this.httpOptions
    );
  }

  /**
   *  Obtiene una nueva session
   */
  getSession(
    user_id: string,
    os: string,
    device: string,
    device_type: string
  ): Observable<any> {
    const config = { invokeUrl: API.dev.url };
    const apigClient = apigClientFactory.newClient(config);
    const additionalParams = {
      headers: { "Content-Type": "application/json" },
      queryParams: {},
    };
    const body = {
      user_id,
      os,
      device,
      device_type,
    };

    return Observable.create((obs) => {
      apigClient
        .invokeApi(
          {},
          API.dev.TemplatePaths.SESSIONS,
          "POST",
          additionalParams,
          body
        )
        .then(function (result) {
          obs.next(result);
        })
        .catch(function (result) {
          obs.error(result);
        });
    });
  }

  /**
   *  Update sessions of user, in this case free the current session
   */
  updateSession(user_id: string, session_id: string): Observable<any> {
    const config = { invokeUrl: API.dev.url };
    const apigClient = apigClientFactory.newClient(config);
    const additionalParams = {
      headers: { "Content-Type": "application/json" },
      queryParams: {},
    };

    const body = {
      session_id,
      user_id,
    };

    return Observable.create((obs) => {
      apigClient
        .invokeApi(
          {},
          API.dev.TemplatePaths.SESSIONS,
          "PATCH",
          additionalParams,
          body
        )
        .then(function (result) {
          obs.next(result);
        })
        .catch(function (result) {
          obs.error(result);
        });
    });
  }

  /**
   *  Check if this credential are valid for tv ptovider
   */
  sigInTVP(email: string, password: string): Observable<any> {
    this.loadingBar.start();
    const instance = this;
    const config = { invokeUrl: API.dev.url };
    const apigClient = apigClientFactory.newClient(config);
    const additionalParams = {
      headers: {
        "Content-Type": "application/json",
      },
      queryParams: {},
    };

    const body = {
      username: email,
      password: password,
    };

    return Observable.create((obs) => {
      apigClient
        .invokeApi(
          {},
          API.dev.TemplatePaths.LOGIN_MC,
          "POST",
          additionalParams,
          body
        )
        .then(function (result) {
          instance.loadingBar.complete();
          obs.next(result);
        })
        .catch(function (result) {
          instance.loadingBar.complete();
          obs.error(result);
        });
    });
  }

  setPreferences(preferences: IPreferences): void {
    localStorage.setItem("PREF", JSON.stringify(preferences));
  }

  getPreferences(): IPreferences {
    const preferences: IPreferences = JSON.parse(localStorage.getItem("PREF"));

    // temporal
    if (preferences === null) {
      const defaultPreferences: IPreferences = { isShowMainAd: true };
      this.setPreferences(defaultPreferences);
    }

    return JSON.parse(localStorage.getItem("PREF"));
  }

  updateProfilePhoto(url: string, userId: string): Observable<any> {
    const config = { invokeUrl: API.dev.url };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {};
    const additionalParams = {
      headers: { "Content-Type": "application/json" },
      queryParams: {},
    };
    const body = { user_id: userId, picture: url };

    // console.log('body:::::');
    // console.log(body);

    return Observable.create((obs) => {
      apigClient
        .invokeApi(
          pathParams,
          API.dev.TemplatePaths.USERS,
          "PUT",
          additionalParams,
          body
        )
        .then(function (result) {
          obs.next(result);
        })
        .catch(function (result) {
          obs.error(result);
        });
    });
  }

  updateUser(attributes: IUser): Observable<any> {
    const config = { invokeUrl: API.dev.url };
    const apigClient = apigClientFactory.newClient(config);

    const pathParams = {};
    const additionalParams = {
      headers: {
        "Content-Type": "application/json",
      },
      queryParams: {},
    };

    return Observable.create((obs) => {
      apigClient
        .invokeApi(
          pathParams,
          API.dev.TemplatePaths.USERS,
          "PUT",
          additionalParams,
          attributes
        )
        .then(function (result) {
          obs.next(result);
        })
        .catch(function (result) {
          obs.next(result);
        });
    });
  }

  // Used 1
  registerUser(user: any) {
    const instance = this;
    this.loadingBar.start();
    const config = { invokeUrl: API.dev.url };
    const apigClient = apigClientFactory.newClient(config);

    const pathParams = {};
    const additionalParams = {
      headers: { "Content-Type": "application/json" },
      queryParams: {},
    };
    const body = {
      email: user.email,
      name: user.name,
      surnames: user.surnames,
      promotion_code: user.promotion_code,
    };

    apigClient
      .invokeApi(
        pathParams,
        API.dev.TemplatePaths.USERS,
        "POST",
        additionalParams,
        body
      )
      .then(function (result) {
        instance.loadingBar.complete();
        Swal.fire({
          type: "success",
          title: "Felicidades",
          text: "Para completar el registro confirma el correo enviado a tu email.\n Y listo ya podrás iniciar sesión en VR APP.",
          showConfirmButton: false,
          timer: 5000,
          background: "rgba(0,0,0,0.9)",
        });
      })
      .catch(function (err) {
        console.log("Error", err.response);
        let config: SweetAlertOptions;
        setTimeout(() => {
          if (err.response.status == 404) {
            config = {
              type: "success",
              title: "",
              text: err.response.data.message,
              showConfirmButton: true,
              background: "rgba(0,0,0,0.9)",
            };
          } else if (err.response.status == 409) {
            config = {
              type: "success",
              title: "",
              text: err.response.data.message,
              showConfirmButton: true,
              background: "rgba(0,0,0,0.9)",
            };
          } else {
            config = {
              type: "error",
              title: "Ups",
              text: "Existe un problema con el sistema. Favor de contactar con el administrador.",
              showConfirmButton: false,
              timer: 5000,
              background: "rgba(0,0,0,0.9)",
            };
          }
          Swal.fire(config);
          instance.loadingBar.complete();
        }, 10);
      });
  }

  changePassword(oldPassword: string, newPassword: string): Promise<any> {
    return this.amplifyService
      .auth()
      .currentAuthenticatedUser()
      .then((user) => {
        return this.amplifyService
          .auth()
          .changePassword(user, oldPassword, newPassword);
      })
      .then((r) => {
        return r;
      })
      .catch((e) => {
        return e;
      });
  }

  forgotPassword(email: string) {
    return this.amplifyService
      .auth()
      .forgotPassword(email)
      .then((data) => {
        console.log("User Service: " + data);
        return data;
      })
      .catch((err) => {
        console.log("User Service: " + err);
        return err;
      });
  }

  submitForgotPassword(email: string, code: string, newPassword: string) {
    return this.amplifyService
      .auth()
      .forgotPasswordSubmit(email, code, newPassword)
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  }

  updatePhoto(
    userID: string,
    imageName: string,
    img64: string
  ): Observable<any> {
    return this.http.post(
      API.dev.url + API.dev.TemplatePaths.USER_IMG,
      {
        user_id: userID,
        ImageName: imageName,
        img64: img64,
      },
      this.httpOptions
    );
  }

  /**
   * Util
   * @param full_name
   */
  public extractName(full_name: string): any {
    const extract_name = { name: " ", lastName: "" };
    const partName = full_name.split(" ");

    if (partName.length === 1) {
      extract_name.name = partName[0];
    } else if (partName.length === 2) {
      extract_name.name = partName[0];
      extract_name.lastName = partName[1];
    } else if (partName.length === 3) {
      extract_name.name = partName[0];
      extract_name.lastName = partName[1] + " " + partName[2];
    } else if (partName.length === 4) {
      extract_name.name = partName[0] + " " + partName[1];
      extract_name.lastName = partName[2] + " " + partName[3];
    }
    return extract_name;
  }

  public registerInOneSignal(email: string): void {
    console.log("Register:");
    const OneSignal = window["OneSignal"] || [];
    OneSignal.sendTags({
      email: email,
    })
      .then(function (tagsSent) {
        OneSignal.setEmail(email);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public parseJwt(token: string) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
}
