import { GlobalsService } from "./globals.service";
import { IReaction } from "./../models/IReaction";
import { IComment } from "./../models/IComment";
//import { VgAPI, BitrateOption } from "videogular2/core";
import { ITrack } from "./../models/ITrack";
import { ActivatedRoute, Router } from "@angular/router";
import { IPlayList } from "../models";
import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import { retry, catchError, map, first } from "rxjs/operators";
import API from "./../configs/ApisConfig";
import Swal from "sweetalert2";
import { AuthenticationService } from "../core/services/authentication.service";
import DATA from "../configs/StaticData";
import { interval } from "rxjs";
import { FullSearchResult, SearchResult } from "../models/Searchs";
/**Bitmovin */
declare var bitmovin: any;
/**Bitmovin */

@Injectable({
  providedIn: "root",
})
export class PlayListControlService {
  /**Bitmovin */
  bitmovin_player = null;
  container = null;
  /**Bitmovin */
  /*Search*/
  public searchPlayListResult: IPlayList = null; // to be deleted in future
  public searchPlayListProgramsResult: IPlayList = null;
  public searchPlayListConcertsResult: IPlayList = null;
  public searchPlayListVideosResult: IPlayList = null;
  public searchQuery: string = null;
  public isLoadingSearchItems = null;
  public searchSectionsResult: SearchResult = null;
  public category_type;


  /*PlayLists */
  playLists: IPlayList[];
  isLoadingPlaLists = true;
  playListsVR: IPlayList[];
  tracksByCategory: ITrack[];
  nextKeyCategory = "";
  nextPageFullSearch: number = 0;
  hasNextFullSearchPage: string;

  /*Playlist */
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  listParam: string;
  trackParam: string;
  origin: string;
  isAllowed = true;

  randomMode = false;
  isAudioMode = false;
  promotionType = "";

  currentPlayList: IPlayList;
  hasMoreItemCurrentList: boolean = false;
  currentIndexTrack: number = 0;
  _currentTrack: ITrack;
  currentState: number;
  //apiPlayer: VgAPI;
  //bitrates: BitrateOption[];
  isLoadingPlayList = true;

  /*Comments */
  currentComments: IComment[];
  isDownloadingComments = true;
  currentTotalComments = 0;

  /* Reactions */
  currentTotalReactions = 0;
  currentReactionUser: IReaction = {
    reaction_id: "",
    user_id: "",
    update_datetime: 0,
    track_id: "",
    reaction: "neutro",
  };

  private trackBehavior = new BehaviorSubject<ITrack>(this.currentTrack);
  observableTrack = this.trackBehavior.asObservable();

  private Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 5000,
    background: " rgb(30, 30, 30)",
    customClass: "vr-toast",
  });

  constructor(
    private _route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private global: GlobalsService,
    public authS: AuthenticationService
  ) {
    this._route.queryParams.subscribe((params) => {
      // console.log('URL CHANGE DETECTED IN PLAY-CONTROL SERVICE');
      // console.log(params);
      this.category_type = params["f"]
      this.trackParam = params["t"];
      this.listParam = params["l"];
      if (this.trackParam && this.listParam) {
        //  console.log('Check access');
        this.checkAccess();
      }

      if (params["query"]) {
        //if contains query of search
        if (!this.searchQuery) {
          this.searchQuery = params["query"];
          this.doSearchInBackgroundMode(params["query"]);
        }
      }
      
    });

    this.currentState = 1;
    if (this.currentPlayList && this.currentPlayList.tracks.length > 0) {
      this.currentIndexTrack = 0;
      this.currentTrack = this.currentPlayList.tracks[0];
    }

    if (this.authS.getCurrentUserInformation()) {
      this.getPlayListsByUser(this.authS.getCurrentUserInformation().user_id);
    }

    interval(1000 * 60 * 15).subscribe((x) => {
      if (this.authS.getCurrentUserInformation()) {
        this.checkSession(
          this.authS.getCurrentUserInformation().user_id,
          this.authS.getCurrentSession().id
        ).subscribe((response) => {
          if (response.session.is_session == true) {
          } else {
            this.authS.logout();
            this.router.navigateByUrl("/login");
          }
        });
      }
    });
  }
  getNewBitmovinInstance(container, config) {
    this.bitmovin_player = new bitmovin.player.Player(container, config);
  }

  async getToken(url: string) {
    return await this.global.getAccessToken(url);
  }

  loadData() {
    switch (this.origin) {
      case "public_search":
        this.getTracksBySearchQuery();
        break;
      case "public_live":
        this.loadDataFromCarousel();
        break;
      case "playlist":
        this.getPlayListByParams();
        break;
      case "playlist-vr":
        this.getPlayListVRByParams();
        break;
      case "last-moment":
        this.getGirdItemsByParams();
        break;
      case "concert":
        //this.getConcertById();
        this.getConcertByTrackId();
        break;
      case "publiccontent":
        this.getPublicContentById();
        break;
      case "programs":
        this.getConcertById();
        break;
      default:
        break;
    }
  }

  /**
   * v1.0
   */
  getComments() {
    this.getCommentsByTrack(this.currentTrack.track_id)
      .toPromise()
      .then((comments) => {
        this.currentComments = comments.comments;
        this.currentTotalComments = this.currentComments.length;
        this.isDownloadingComments = false;
      })
      .catch((error) => {
        //console.log('ERROR DOWNLOAD COMMENTS');
        //console.log(error);
      });
  }

  /**
   * v1.0
   */
  getDetailsForCurrentTrack(): void {
    this.getComments();
    this.getReactionsByTrack(this.currentTrack.track_id)
      .toPromise()
      .then((reactions) => {
        // console.log('Total reactions: ');
        // console.log(reactions);
        this.currentTotalReactions = reactions.reactions.length;
      });

    if (this.authS.getCurrentUserInformation()) {
      // If user is logged in get her reaction for current track
      this.getReactionByUserAndTrack(
        this.currentTrack.track_id,
        this.authS.getCurrentUserInformation().user_id
      )
        .toPromise()
        .then((data) => {
          // console.log(data);

          if (data.reaction[0]) {
            this.currentReactionUser = data.reaction[0];
          } else {
            //  console.log('neutro');

            this.currentReactionUser = {
              reaction_id: "",
              user_id: this.authS.getCurrentUserInformation().user_id,
              update_datetime: 0,
              track_id: this.currentTrack.track_id,
              reaction: "neutro",
            };
          }
          // console.log('Reaction of user:');
          // console.log(this.currentReactionUser);
        })
        .catch((err) => {});
    }
  }

  removeTrackOfPlayList(idTrack: any, idPlayList) {
    return this.http.delete<any>(
      API.dev.url +
        API.dev.TemplatePaths.PLAYLISTS +
        "/" +
        idTrack +
        "/" +
        idPlayList,
      this.httpOptions
    );
  }

  public downloadPlayList() {
    this.getPlayListById(this.currentPlayList.playlist_id).subscribe((data) => {
      this.currentPlayList = data.playlist;
    });
  }

  /*
   * Add a comment to track
   */
  public addComment(
    message: string,
    trackId: String,
    userId: string
  ): Observable<any> {
    return this.http
      .post<any>(
        API.dev.url + API.dev.TemplatePaths.COMMENT,
        {
          track_id: trackId,
          user_id: userId,
          message: message,
        },
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  public updateComment(commentId: String, message: String): Observable<any> {
    return this.http
      .put<any>(
        API.dev.url + API.dev.TemplatePaths.COMMENT,
        {
          comment_id: commentId,
          message: message,
        },
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  public getCommentsByTrack(trackId: String): Observable<any> {
    this.isDownloadingComments = true;
    return this.http.get<any>(
      API.dev.url + API.dev.TemplatePaths.COMMENT + "/" + trackId,
      this.httpOptions
    );
  }

  public deleteComment(commentId: any): Observable<any> {
    return this.http
      .delete<any>(
        API.dev.url + API.dev.TemplatePaths.COMMENT + "/comment/" + commentId,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /* Reactions */

  public getReactionsByTrack(trackId: any): Observable<any> {
    return this.http.get<any>(
      API.dev.url + API.dev.TemplatePaths.REACTIONS + "/" + trackId,
      this.httpOptions
    );
  }

  public getReactionByUserAndTrack(
    trackId: any,
    userId: string
  ): Observable<any> {
    return this.http.get<any>(
      API.dev.url +
        API.dev.TemplatePaths.REACTIONS +
        "/" +
        trackId +
        "/" +
        userId,
      this.httpOptions
    );
  }

  public updateReaction(trackId, userId, reaction): Observable<any> {
    return this.http
      .put<any>(
        API.dev.url +
          API.dev.TemplatePaths.REACTIONS +
          "/" +
          trackId +
          "/" +
          userId +
          "/" +
          reaction,
        {},
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /*  PlayList */

  public addTrackToPlayList(playListId: any, trackId: any) {
    return this.http
      .post<any>(
        API.dev.url +
          API.dev.TemplatePaths.PLAYLISTS +
          "/" +
          playListId +
          "/" +
          trackId,
        {},
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  public handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage =
        "Error  Code: " + error.status + "\nMessage: " + error.message;
    }
    return throwError(errorMessage);
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  // Tracks of carrousel

  public getCarouselItems(): Observable<any> {
    return this.http.get<any>(
      API.dev.url + API.dev.TemplatePaths.CAROUSEL + "?type=carousel",
      this.httpOptions
    );
  }

  /**
   * getTrackById
   */
  public getTrackById(id: string) {
    return this.http.get<any>(
      API.dev.url + API.dev.TemplatePaths.TRACKS + id,
      this.httpOptions
    );
  }

  public getRecommendeList() {
    return this.http.get<any>(
      API.dev.url + API.dev.TemplatePaths.GET_RECOMENDED_LIST,
      this.httpOptions
    );
  }

  public loadDataFromCarousel(): void {
    const playListLive: IPlayList = {
      description: "Transmisiones de VR",
      genres: [],
      image: "",
      tracks: [],
      playlist_id: "",
      playlist_name: "En vivo",
    };
    this.getTrackById(this.trackParam)
      .toPromise()
      .then((data) => {
        var tracks = [];
        tracks.push(data.track);
        this.getRecommendeList().toPromise().then((data)=>{
          tracks=tracks.concat(data.collection.playlists[0].tracks); 
          playListLive.tracks = tracks;
          this.currentPlayList = playListLive;
          this.isLoadingPlayList = false;
          this.getCurrentByParams();
          this.isLoadingPlayList = false;
        }).catch((err)=>{
        });
        

      })
      .catch((err) => {
        //console.log('Error al cargar datos del carousel::::');
        //console.log(err);
      });
  }

  // Tracks of search

  /**
   * getTracksBySearchQuery
   */
  public async getTracksBySearchQuery() {
    if (
      this.currentPlayList &&
      this.currentPlayList !== null &&
      this.currentPlayList !== undefined
    ) {
      this.getCurrentByParams();
      this.isLoadingPlayList = false;
      return;
    }
    // no routing
    await this.doSearchInBackgroundMode(this.global.getLastSeacrh(), false);
    // find the section search result and call getCurrentByParams()
    const finder = (play: IPlayList): ITrack => {
      return play.tracks.find((x) => {
        if (x.track_id === this.trackParam) {
          this.currentPlayList = play;
          this.currentTrack = x;
          return true;
        }
      });
    };
    //console.log([this.searchPlayListConcertsResult, this.searchPlayListProgramsResult, this.searchPlayListVideosResult]);

    //search in concerts
    let res = finder(this.searchPlayListConcertsResult);
    if (res === undefined) res = finder(this.searchPlayListProgramsResult);
    if (res === undefined) res = finder(this.searchPlayListVideosResult);
    this.currentIndexTrack = this.currentPlayList.tracks.indexOf(
      this.currentTrack
    );
    this.getDetailsForCurrentTrack();
    this.isLoadingPlayList = false;

    // this.getSearchResultService(this.global.getLastSeacrh()).toPromise().then(r => {
    //   const playList: IPlayList = {
    //     description: 'Disfruta de lo que hemos encontrado para ti',
    //     genres: [],
    //     image: '',
    //     tracks: [],
    //     playlist_id: '',
    //     playlist_name: 'Relacionados con ' + (this.searchQuery ? this.searchQuery : ' la última busqueda')
    //   };
    //   playList.tracks = r.tracks;
    //   this.currentPlayList = playList;
    //   this.currentIndexTrack = 0;
    //   this.currentTrack = this.currentPlayList.tracks[this.currentIndexTrack];
    //   setTimeout(() => {
    //     this.bitmovin_player.play();
    //   }, 5000);
    //   // console.log('CURRENT TRACK IN SEARCH ', this.currentTrack);
    //   this.getCurrentByParams();
    //   this.isLoadingPlayList = false;
    // }).catch(e => {
    //   //console.log('Error in load search data: ', e);
    // });
  }

  // Tracks by playlist

  /**
   * getPlayListByParams
   */
  public getPlayListByParams() {
    const tempSubscription = this.getPlayListById(this.listParam).subscribe(
      (data) => {
        this.currentPlayList = data.playlist;
        this.isLoadingPlayList = false;
        this.getCurrentByParams();
        tempSubscription.unsubscribe();
      }
    );
  }

  public getPlayListVRByParams() {
    const subsTemp = this.getPlayListByIdVR(this.listParam).subscribe(
      (data) => {
        this.currentPlayList = data.playlist;
        this.getCurrentByParams();
        this.isLoadingPlayList = false;
        subsTemp.unsubscribe();
      }
    );
  }

  // Grid

  public getGridItems(): Observable<any> {
    return this.http.get<any>(
      API.dev.url + API.dev.TemplatePaths.CAROUSEL + "?type=grid",
      this.httpOptions
    );
  }

  public getGirdItemsByParams(): void {
    const playListLive: IPlayList = {
      description: "",
      genres: [],
      image: "",
      tracks: [],
      playlist_id: "",
      playlist_name: "Ultimo Momento",
    };
    this.getTrackById(this.trackParam)
      .toPromise()
      .then((data) => {
        var tracks = [];
        tracks.push(data.track);
        this.getRecommendeList().toPromise().then((data)=>{
          tracks=tracks.concat(data.collection.playlists[0].tracks); 
          playListLive.tracks = tracks;
          this.currentPlayList = playListLive;
          this.getCurrentByParams();
          this.isLoadingPlayList = false;
        }).catch((err)=>{
        });
      })
      .catch((err) => {
        //console.log('Error download data from grid');
      });
  }

  public getConcertByTrackId(): void {
    const playListLive: IPlayList = {
      description: "",
      genres: [],
      image: "",
      tracks: [],
      playlist_id: "",
      playlist_name: "Conciertos",
    };
    this.getTrackById(this.trackParam)
      .toPromise()
      .then((data) => {
        var tracks = [];
        tracks.push(data.track);
        this.getRecommendeList().toPromise().then((data)=>{
          tracks=tracks.concat(data.collection.playlists[0].tracks); 
          playListLive.tracks = tracks;
          this.currentPlayList = playListLive;
          this.getCurrentByParams();
          this.isLoadingPlayList = false;
        }).catch((err)=>{
        });
      })
      .catch((err) => {
        //console.log('Error download data from grid');
      });
  }

  public getPublicContentById(): void {
    const playListLive: IPlayList = {
      description: "Video Rola",
      genres: [],
      image: "",
      tracks: [],
      playlist_id: "",
      playlist_name: "Conciertos",
    };
    this.getTrackById(this.trackParam)
      .toPromise()
      .then((data) => {
        var tracks = [];
        tracks.push(data.track);
        this.getRecommendeList().toPromise().then((data)=>{
          tracks=tracks.concat(data.collection.playlists[0].tracks); 
          playListLive.tracks = tracks;
          this.currentPlayList = playListLive;
          this.getCurrentByParams();
          this.isLoadingPlayList = false;
        }).catch((err)=>{
        });
      })
      .catch((err) => {
        //console.log('Error download data from grid');
      });
  }

  // Unir con metodos que obtengan track y creen playlist de diferente tipo
  // TODO - Remain integrate show pagination
  public async getConcertById(nextPage: boolean = false) {
    const playListLive: IPlayList = {
      description: "Video Rola",
      genres: [],
      image: "",
      tracks: [],
      playlist_id: "",
      playlist_name: "Programas",
    };
    // * We should get the show data
    const data = await this.getTracksShow(
      this.listParam,
      this.nextKeyCategory
    ).toPromise();
    this.nextKeyCategory =
      data.last_key == null || data.last_key == "none" || data.last_key == "-"
        ? ""
        : data.last_key; // * set last next key page
    this.hasMoreItemCurrentList =
      data.last_key != null &&
      data.last_key &&
      data.last_key != "none" &&
      data.last_key != "-";
    if (!nextPage) {
      playListLive.tracks = data.events;
      this.currentPlayList = playListLive;
      this.getCurrentByParams();
      this.isLoadingPlayList = false;
    } else {
      this.currentPlayList.tracks = this.currentPlayList.tracks.concat(
        data.events
      );
    }
  }

  //

  /**
   * checkAccess
   */
  public verifyAccess(user_id: string, track_id: string) {
    return this.http.get<any>(
      API.dev.url +
        API.dev.TemplatePaths.CHECK_SUBSCRIPTION +
        "/" +
        user_id +
        "/" +
        track_id,
      this.httpOptions
    );
  }

  public verifyPublicAccess(track_id: string) {
    return this.http.get<any>(
      API.dev.url + API.dev.TemplatePaths.TRACKS + "public/" + track_id,
      this.httpOptions
    );
  }

  // Associate code to user

  /**
   * setCode
   */
  public setCode(code: string): Observable<any> {
    return this.http.post(
      `${API.dev.url + API.dev.TemplatePaths.USER_CODE}${code}/${
        this.authS.getCurrentUserInformation().user_id
      }`,
      this.httpOptions
    );
  }

  /**
   * checkAccess
   */
  public checkAccess() {
    const user = this.authS.getCurrentUserInformation();
    if (user) {
      this.verifyAccess(user.user_id, this.trackParam)
        .toPromise()
        .then((d) => {
          if (d.allow && d.allow === true) {
            this.promotionType = d.promotion_type ? d.promotion_type : "video";
            if (this.promotionType === "audio" && this.isAudioMode != false) {
              this.isAudioMode = true;
            } else {
              this.isAudioMode = false;
            }
            setTimeout(() => {
              this.checkAvailability2();
            }, 1000);
          } else {
            this.isAllowed = false;
            window.location.href =
              "/promotions?t=" +
              btoa(this.trackParam) +
              "&ui=" +
              btoa(this.authS.getCurrentUserInformation().user_id) +
              "&origin=web-app";
          }
        })
        .catch((er) => {
          this.isAllowed = false;
          window.location.href =
            "/promotions?t=" +
            btoa(this.trackParam) +
            "&ui=" +
            btoa(this.authS.getCurrentUserInformation().user_id) +
            "&origin=web-app";
          // Swal.fire({
          //   title: "Este video es sólo para suscriptores!",
          //   //text: 'Necesitas un codigo promocional para verlo!',
          //   type: "warning",
          //   showCancelButton: true,
          //   showCloseButton: true,
          //   showConfirmButton: true,
          //   confirmButtonColor: "rgba(62, 62, 62, 0.911)",
          //   cancelButtonColor: "rgba(62, 62, 62, 0.911)",
          //   //cancelButtonColor: 'rgba(255, 255, 255, 0.1)',
          //   confirmButtonText: "Ingresar un código!",
          //   cancelButtonText: "Suscribirse!",
          //   background: "rgba(0,0,0,0.9)",
          //   backdrop: "rgba(0,0,0,0.8)",
          //   allowEscapeKey: false,
          //   allowEnterKey: false,
          //   allowOutsideClick: false,
          //   //footer: `
          //   //<a href="/promotions?t=${btoa(this.trackParam)}&ui=${btoa(this.authS.getCurrentUserInformation().user_id)}&origin=${'web-app'}" class="btn btn-outline-dark" style="color: white;  background: rgba(62, 62, 62, 0.911)">
          //   //Suscribete! </a>`
          // })
          //   .then((result) => {
          //     if (result.value) {
          //       this.requestCode();
          //     } else if (result.dismiss === Swal.DismissReason.cancel) {
          //       window.location.href =
          //         "/promotions?t=" +
          //         btoa(this.trackParam) +
          //         "&ui=" +
          //         btoa(this.authS.getCurrentUserInformation().user_id) +
          //         "&origin=web-app";
          //     } else {
          //       // Si presiona en cancelar al pedir si tiene codigo
          //       this.isAllowed = false;
          //       this.router.navigateByUrl("/main");
          //     }
          //   })
          //   .catch((err) => {
          //     //console.log('error modal tengo codigo');
          //   });
        });
    } else {
      // No hay una sesion

      this.verifyPublicAccess(this.trackParam)
        .toPromise()
        .then((data) => {
          try {
            this.promotionType = data.promotion_type;
            if (this.promotionType === "audio") {
              this.isAudioMode = true;
            } else {
              this.isAudioMode = false;
            }
          } catch (error) {}
        })
        .catch((err) => {
          //console.log('last :: BLOCKED BY SESSION');
          this.global.isBlockBySession = true;
          this.router.navigateByUrl("/login");
        });
    }
  }

  requestCode() {
    Swal.fire({
      title: "Código de promoción:",
      input: "text",
      allowEscapeKey: false,
      allowEnterKey: false,
      inputPlaceholder: "Ingresa codigo!",
      inputClass: "input-vr",
      inputAttributes: {
        autocapitalize: "off",
        color: "#000",
        background: "#fff",
      },
      showCancelButton: true,
      confirmButtonText: "Continuar",
      showLoaderOnConfirm: true,
      background: "rgba(0,0,0,0.3)",
      backdrop: "rgba(0,0,0,0.9)",
      confirmButtonColor: "rgba(62, 62, 62, 0.911)",
      cancelButtonColor: "rgba(255, 255, 255, 0.1)",
      preConfirm: (login) => {
        return this.setCode(login)
          .toPromise()
          .then((data) => {
            return data;
          })
          .catch((err) => {
            return err;
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })
      .then((resultCode) => {
        console.log("result of code");
        console.log(resultCode.value);
        if (resultCode.value && resultCode.value.promotion_id) {
          this.isAllowed = true;
          this.bitmovin_player.play();
        } else {
          let msg = "El código de promoción es incorrecto";
          if (resultCode.value && resultCode.value.status == 404) {
            msg = resultCode.value.error.message;
          } else if (resultCode.value && resultCode.value.status == 409) {
            msg = resultCode.value.error.message;
          }

          Swal.fire({
            title: "",
            text: msg,
            type: "error",
            showCancelButton: true,
            confirmButtonText: "Reintentar",
            cancelButtonText: "Cancelar",
            background: "rgba(0,0,0,0.3)",
            backdrop: "rgba(0,0,0,0.9)",
            confirmButtonColor: "rgba(62, 62, 62, 0.911)",
            cancelButtonColor: "rgba(255, 255, 255, 0.1)",
          }).then((result) => {
            if (result.value) {
              this.requestCode();
            } else {
              this.isAllowed = false;
              this.router.navigateByUrl("/main");
            }
          });
        }
      })
      .catch((err) => {
        console.log("Error code");
        console.log(err);
        this.isAllowed = false;
        this.router.navigateByUrl("/main");
      });
  }

  set currentTrack(value) {
    this._currentTrack = value;
    this.trackBehavior.next(value);
  }

  get currentTrack() {
    return this._currentTrack;
  }

  /**
   * v1.0
   */
  // public async checkAvailability() {

  //   this.global.getCurrentPositionByIp().toPromise()
  //     .then(data => {
  //       const country = data.country + '-' + data.countryCode;
  //       console.log('Location: ', country);
  //       if (this.currentTrack && this.currentTrack.countries) {
  //         for (let index = 0; index < this.currentTrack.countries.length; index++) {
  //           const element = this.currentTrack.countries[index];

  //           if (country in element) {
  //             console.log('TRACK BLOCK BY LOCATION');
  //             this.isAllowed = false;
  //             break;
  //           } else {
  //             this.isAllowed = true;
  //           }
  //         }
  //         console.log('allowed value');
  //         console.log(this.isAllowed);
  //       }

  //       if (!this.isAllowed) {
  //         Swal.fire({
  //           title: 'Lo sentimos!',
  //           text: 'Este video no está disponible en tu región',
  //           type: 'warning',
  //           showCancelButton: true,
  //           confirmButtonColor: 'rgba(62, 62, 62, 0.911)',
  //           cancelButtonColor: 'rgba(255, 255, 255, 0.1)',
  //           confirmButtonText: 'Ok!',
  //           cancelButtonText: 'Cancelar!',
  //           background: 'rgba(0,0,0,0.9)',
  //           backdrop: 'rgba(0,0,0,0.8)',
  //           allowEscapeKey: false,
  //           allowEnterKey: false,
  //           allowOutsideClick: false

  //         }).then((result) => {
  //           if (result.value) {
  //             if (this.currentIndexTrack + 1 < this.currentPlayList.tracks.length) {
  //               this.currentIndexTrack++;
  //               this.currentTrack = this.currentPlayList.tracks[this.currentIndexTrack];
  //               this.apiPlayer.play();
  //             } else {
  //               this.currentIndexTrack = 0;
  //               this.currentTrack = this.currentPlayList.tracks[this.currentIndexTrack];
  //               this.apiPlayer.play();
  //             }
  //           } else {
  //             this.isAllowed = false;
  //             this.router.navigateByUrl('/main');
  //           }
  //         }).catch(err => {

  //         });
  //       }
  //     }, error => {
  //       console.log('ERROR WHEN TRY GET LOCATION ');
  //       console.log(error);
  //     });

  // }

  /**
   * v2.0
   */
  public async checkAvailability2() {
    const t = this.global.getPublicIp().subscribe(
      (data) => {
        //console.log('IP', data);
        const allowdObser = this.global.isTrackAllowed(data.ip).subscribe(
          (allow) => {
            //console.log('ALLOW', allow);
            if (allow.allow === "no") {
              this.isAllowed = false;
              Swal.fire({
                title: "Lo sentimos!",
                text: "Este video no está disponible en tu región",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "rgba(62, 62, 62, 0.911)",
                cancelButtonColor: "rgba(255, 255, 255, 0.1)",
                confirmButtonText: "Ok!",
                cancelButtonText: "Cancelar!",
                background: "rgba(0,0,0,0.9)",
                backdrop: "rgba(0,0,0,0.8)",
                allowEscapeKey: false,
                allowEnterKey: false,
                allowOutsideClick: false,
              })
                .then((result) => {
                  if (result.value) {
                    if (
                      this.currentIndexTrack + 1 <
                      this.currentPlayList.tracks.length
                    ) {
                      this.currentIndexTrack++;
                      this.currentTrack =
                        this.currentPlayList.tracks[this.currentIndexTrack];
                      //this.apiPlayer.play();
                      this.bitmovin_player.play();
                    } else {
                      this.currentIndexTrack = 0;
                      this.currentTrack =
                        this.currentPlayList.tracks[this.currentIndexTrack];
                      //this.apiPlayer.play();
                      this.bitmovin_player.play();
                    }
                  } else {
                    this.isAllowed = false;
                    this.router.navigateByUrl("/main");
                  }
                })
                .catch((err) => {});
            } else {
              this.isAllowed = true;
            }
            allowdObser.unsubscribe();
          },
          (err) => {}
        );

        t.unsubscribe();
      },
      (err) => {}
    );
  }

  /**
   * v.10
   */
  public getCurrentByParams() {
    const foundTrack = this.currentPlayList.tracks.find((x) => {
      if (x.track_id === this.trackParam) {
        return true;
      }
    });
    this.currentIndexTrack = 0;
    this.currentTrack = foundTrack
      ? foundTrack
      : this.currentPlayList.tracks[this.currentIndexTrack];
    // console.log(':CURENT TRACK:');
    // console.log(this.currentTrack);
    this.currentIndexTrack = this.currentPlayList.tracks.indexOf(
      this.currentTrack
    );
    this.getDetailsForCurrentTrack();
  }

  public resetPlayer() {}
  /**
   * v1.0
   */
  public sortTracks() {
    this.randomMode = false;
    const reverseSortedArray = this.currentPlayList.tracks.sort(function (
      a,
      b
    ) {
      if (a.name > b.name) return 1;
      else if (a.name < b.name) return -1;
      return 0;
    });

    this.currentPlayList.tracks = reverseSortedArray;
    this.currentIndexTrack = 0;
    this.currentTrack = this.currentPlayList.tracks[0];
    //this.apiPlayer.play();
    this.bitmovin_player.play();
    this.changeParams(this.listParam, this.currentTrack.track_id);
  }

  public getNextRandom(a: number, b: number, last: number) {
    return Math.round(Math.random() * (b - a) + a);
  }

  /**
   *  v1.0
   * setAudioMode
   */
  public setAudioMode() {
    this.isAudioMode = !this.isAudioMode;
    this.bitmovin_player.pause();
    //this.bitmovin_player.destroy();
    let current_seek = this.bitmovin_player.getCurrentTime();
    this.currentTrack = this.currentTrack;

    setTimeout(() => {
      //this.apiPlayer.play();
      this.bitmovin_player.seek(current_seek);
      this.bitmovin_player.play();
    }, 5000);
  }
  /**
   * v1.0
   */
  changeParams(list: string, track: string) {
    this.router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        l: list,
        t: track,
      },
    });
  }

  /* PlayList */

  /**
   *  Get all the playlists of the logged in user.  // To remove
   */
  public getData() {
    this.isLoadingPlaLists = true;
    const id = this.authS.getCurrentUserInformation().user_id;
    this.getPlayListsByUserService(id).subscribe((e) => {
      this.playLists = e.collection.playlists;
      this.isLoadingPlaLists = false;
    });
  }

  /**
   * getDataRec
   */
  public getDataRec() {
    this.isLoadingPlaLists = true;
    this.getRecommendedPlayListsService().subscribe((e) => {
      this.playListsVR = e.collection.playlists;
      this.isLoadingPlaLists = false;
    });
  }

  private updateNextPageOnFullSearchResut() {
    this.nextPageFullSearch =
      this.hasNextFullSearchPage == "true" ? this.nextPageFullSearch + 1 : 0;
  }

  /**
   * getTracksByCategory
   */
  public getAnotherPage(origin?: string, key?: string, meta?: string) {
    let id: string;
    let new_id: string;
    console.log(this.category_type)
    switch (origin) {
      case "concerts":
        id = DATA.streams_categories.concerts.id;
        break;
      case "related-videos":
        id = DATA.streams_categories.video.id;
        break;
      case "publiccontent":
        id = DATA.streams_categories.publiccontent.id;
        break;
      default:
        id = DATA.streams_categories.programmes.id;
        break;
    }
    switch (this.category_type) {
      case "concert":
        new_id = DATA.streams_categories.concerts.id;
        break;
      case "videos":
        new_id = DATA.streams_categories.video.id;
        break;
      default:
        new_id = DATA.streams_categories.programmes.id;
        break;
    }
    
    if (this.authS.getCurrentUserInformation() == null) {
      if (origin == "search-related-videos") {
        setTimeout(() => {
          
        
        this.getFullSearchResult(new_id,meta, this.nextPageFullSearch, "")
          .pipe(first())
          .subscribe((data) => {
            this.hasNextFullSearchPage = data.next_key;
            this.tracksByCategory = this.tracksByCategory.concat(data.tracks);
            this.updateNextPageOnFullSearchResut();
          });
        }, 2000);
        return;
      }
      const tempSubs = this.getAnotherPageService(id, key).subscribe(
        (e) => {
          this.nextKeyCategory = e.next_key;
          this.tracksByCategory = this.tracksByCategory.concat(e.tracks);
          tempSubs.unsubscribe();
        },
        (err) => {
          tempSubs.unsubscribe();
        }
      );
    } else {
      if (origin == "search-related-videos") {
        setTimeout(() => {
        this.getFullSearchResult(
          new_id,
          meta,
          this.nextPageFullSearch,
          this.authS.getCurrentUserInformation().user_id
        )
          .pipe(first())
          .subscribe((data) => {
            this.hasNextFullSearchPage = data.next_key;
            this.tracksByCategory = this.tracksByCategory.concat(data.tracks);
            this.updateNextPageOnFullSearchResut();
          });
        }, 2000);
        return;
      }
      const tempSubs = this.getAnotherPageAndUserIdService(
        id,
        this.authS.getCurrentUserInformation().user_id,
        key
      ).subscribe(
        (e) => {
          this.nextKeyCategory = e.next_key;
          this.tracksByCategory = this.tracksByCategory.concat(e.tracks);
          tempSubs.unsubscribe();
        },
        (err) => {
          tempSubs.unsubscribe();
        }
      );
    }
  }

  public async getAllOrPageShows() {
    let page =
      this.nextKeyCategory != "none" && this.nextKeyCategory != "-"
        ? this.nextKeyCategory
        : "";
    const data: any = await this.http
      .get(`${API.dev.url + API.dev.TemplatePaths.GET_ALL_SHOWS}/${page}`)
      .toPromise();
    this.nextKeyCategory = data.last_key;
    this.isLoadingPlaLists = false;
    this.tracksByCategory = this.tracksByCategory
      ? this.tracksByCategory.concat(data.events)
      : [data.events];
  }
  /**
   * getTracksByCategory
   */
  public getTracksByCategory(origin?: string, meta?: string) {
    this.isLoadingPlaLists = true;
    let id: string;
    let new_id;
    console.log(this.category_type)
    switch (origin) {
      case "concerts":
        id = DATA.streams_categories.concerts.id;
        break;
      case "related-videos":
        id = DATA.streams_categories.video.id;
        break;
      case "publiccontent":
        id = DATA.streams_categories.publiccontent.id;
        break;
      default:
        id = DATA.streams_categories.programmes.id;
        break;
    }
    switch (this.category_type) {
      case "concert":
        new_id = DATA.streams_categories.concerts.id;
        break;
      case "videos":
        new_id = DATA.streams_categories.video.id;
        break;
      default:
        new_id = DATA.streams_categories.programmes.id;
        break;
    }
    if (this.authS.getCurrentUserInformation() == null) {
      if (origin == "search-related-videos") {
        setTimeout(() => {
        this.getFullSearchResult(new_id,meta, this.nextPageFullSearch, "")
          .pipe(first())
          .subscribe((data) => {
            this.hasNextFullSearchPage = data.next_key;
            this.tracksByCategory = data.tracks;
            this.isLoadingPlaLists = false;
            this.updateNextPageOnFullSearchResut();
          });
        }, 5000);
        return;
      }
      const tempSubs = this.getTracksByCategoryService(id).subscribe(
        (e) => {
          this.nextKeyCategory = e.next_key;
          this.tracksByCategory = e.tracks;
          this.isLoadingPlaLists = false;
          tempSubs.unsubscribe();
        },
        (err) => {
          tempSubs.unsubscribe();
        }
      );
    } else {
      if (origin == "search-related-videos") {
        setTimeout(() => {
        this.getFullSearchResult(
          new_id,
          meta,
          this.nextPageFullSearch,
          this.authS.getCurrentUserInformation().user_id
        )
          .pipe(first())
          .subscribe((data) => {
            this.hasNextFullSearchPage = data.next_key;
            this.tracksByCategory = data.tracks;
            this.isLoadingPlaLists = false;
            this.updateNextPageOnFullSearchResut();
          });
          }, 5000);
        return;
      }
      const tempSubs = this.getTracksByCategoryAndUserIdService(
        id,
        this.authS.getCurrentUserInformation().user_id
      ).subscribe(
        (e) => {
          this.nextKeyCategory = e.next_key;
          this.tracksByCategory = e.tracks;
          this.isLoadingPlaLists = false;
          tempSubs.unsubscribe();
        },
        (err) => {
          tempSubs.unsubscribe();
        }
      );
    }
  }

  /**
   * v1.0
   * Get all recommended playlists by VideoRola
   * @param userId User id.
   * @param origin Origin of where it was inviked.
   */
  public getRecommendedPlayLists() {
    this.isLoadingPlaLists = true;
    this.getRecommendedPlayListsService().subscribe((e) => {
      this.playListsVR = e.collection.playlists;
      this.isLoadingPlaLists = false;
    });
  }

  /**
   * v1.0
   * Get all the playlists of the logged in user.
   * @param userId User id.
   * @param origin Origin of where it was inviked.
   */
  public getPlayListsByUser(userId: string, origin?: string) {
    // this.playLists = null;
    switch (origin) {
      case "my-play-lists":
        this.isLoadingPlaLists = true;
        break;
      default:
    }
    // if (!this.playLists) {
    this.getPlayListsByUserService(userId)
      .toPromise()
      .then((data) => {
        this.playLists = data.collection.playlists;
        switch (origin) {
          case "my-play-lists":
            this.isLoadingPlaLists = false;
            break;
          default:
        }
      });
    //}
  }

  public extractPlayList(res: Response): any {
    const body = res;
    return body;
  }

  getPlayLists(): Observable<any> {
    return this.http
      .get(API.dev.url + API.dev.TemplatePaths.COLLECTIONS)
      .pipe(map(this.extractData));
  }

  getPlayListById(id: String): Observable<any> {
    return this.http
      .get(API.dev.url + API.dev.TemplatePaths.PLAYLISTS + "/" + id)
      .pipe(map(this.extractData));
  }

  getPlayListByIdVR(id: String): Observable<any> {
    return this.http
      .get(API.dev.url + API.dev.TemplatePaths.PLAYLISTS + "/video-rola/" + id)
      .pipe(map(this.extractData));
  }
  /**
   * v1.0
   * @param user_id
   */
  getPlayListsByUserService(user_id: string): Observable<any> {
    return this.http
      .get(API.dev.url + API.dev.TemplatePaths.USER_PLAYLISTS + user_id)
      .pipe(map(this.extractData));
  }

  // getCommentsByTrack(id: String) {
  //   return this.http.get(API.dev.url + API.dev.TemplatePaths.COMMENTS + '/' + id).pipe(
  //     map(this.extractData));
  // }

  addPlayList(playList: IPlayList) {
    return this.http.post<any>(
      API.dev.url + API.dev.TemplatePaths.PLAYLISTS,
      { queryStringParameters: playList },
      this.httpOptions
    );
  }

  removePlayList(id: any) {
    // console.log(id);
    return this.http.delete<any>(
      API.dev.url + API.dev.TemplatePaths.PLAYLISTS + "/" + id,
      this.httpOptions
    );
  }

  /**
   *  v1.0
   */
  getRecommendedPlayListsService(): Observable<any> {
    return this.http
      .get(API.dev.url + API.dev.TemplatePaths.RECOMMEND)
      .pipe(map(this.extractData));
  }

  /**
   * v1.0
   * @param categoryID Id of the category to get.
   */
  getTracksByCategoryService(categoryID?: string): Observable<any> {
    return this.http
      .get(
        API.dev.url + API.dev.TemplatePaths.TRACKS + "category/" + categoryID
      )
      .pipe(map(this.extractData));
  }

  /**
   * v1.0
   * @param categoryID Id of the category to get.
   */
  getTracksByCategoryAndUserIdService(
    categoryID?: string,
    userID?: string
  ): Observable<any> {
    return this.http
      .get(
        API.dev.url +
          API.dev.TemplatePaths.TRACKS +
          "category/user/" +
          categoryID +
          "/" +
          userID
      )
      .pipe(map(this.extractData));
  }

  /**
   *  v1.0
   * @param param Crterion of search
   */
  getSearchResultService(param: string): Observable<any> {
    return this.http
      .get(API.dev.url + API.dev.TemplatePaths.SEARCH + param)
      .pipe(map(this.extractData));
  }

  /**
   *  v1.0
   * @param param Crterion of search
   * @deprecated
   */
  getSearchResultAndUserService(
    param: string,
    userID: string
  ): Observable<any> {
    return this.http
      .get(API.dev.url + API.dev.TemplatePaths.SEARCH + param + "/" + userID)
      .pipe(map(this.extractData));
  }
  /**
   * v2.0
   * @param param Crterion of search
   */
  getSearchResultInAuthMode(
    param: string,
    userID: string
  ): Observable<SearchResult> {
    return this.http.get<SearchResult>(
      `${API.dev.url + API.dev.TemplatePaths.SEARCH}new/${param + "/" + userID}`
    );
  }
  /**
   * @version 2.0
   */
  getSearchResultInPublicMode(param: string): Observable<SearchResult> {
    return this.http.get<SearchResult>(
      `${API.dev.url + API.dev.TemplatePaths.SEARCH}new/${param}`
    );
  }

  /**
   * @version 2.0
   */
  getFullSearchResult(
    id:string,
    query: string,
    page: number,
    user: string
  ): Observable<FullSearchResult> {


    
    if(`${user}`){
     
      return this.http.get<FullSearchResult>(
        `${
          API.dev.url + API.dev.TemplatePaths.PRIVATE_SEARCH
        }/${query}/${id}/${page}/${user}`
      );
    }
    else{
      return this.http.get<FullSearchResult>(
        `${
          API.dev.url + API.dev.TemplatePaths.PUBLIC_SEARCH
        }/${query}/${id}/${page}/`
      );
    }
    /*
    return this.http.get<FullSearchResult>(
      `${
        API.dev.url + API.dev.TemplatePaths.FULL_SEARCH
      }/${query}/${page}/${user}`
    );*/
  }

  /**
   * v1.0
   * @param categoryID Id of the category to get
   * @param lastKey LastKey of page
   */
  getAnotherPageService(
    categoryID?: string,
    lastKey?: string
  ): Observable<any> {
    return this.http
      .get(
        API.dev.url +
          API.dev.TemplatePaths.TRACKS +
          "category/" +
          categoryID +
          "/" +
          lastKey
      )
      .pipe(map(this.extractData));
  }

  /**
   * v1.0
   * @param categoryID Id of the category to get
   * @param lastKey LastKey of page
   */
  getAnotherPageAndUserIdService(
    categoryID?: string,
    userID?: string,
    lastKey?: string
  ): Observable<any> {
    return this.http
      .get(
        API.dev.url +
          API.dev.TemplatePaths.TRACKS +
          "category/user/" +
          categoryID +
          "/" +
          userID +
          "/" +
          lastKey
      )
      .pipe(map(this.extractData));
  }

  /**
   *  v1.0
   *  Add playlist
   *  @param origin : Origin of where it was invoked.
   */
  async addNewPlayList(origin: string) {
    const { value: formValues } = await Swal.fire({
      background: "rgb(35, 35, 35)",
      html: `
      <h5 class="text-light text f-m" style="border-bottom:1px solid rgba(255,255,255,0.6);">
        <img src="assets/ICONOS/png/ICONO NUEVA PLAYLIST.png" width="35" alt="" style="background-color: transparent;">
        &nbsp;
        Crea una nueva playlist
      </h5>
      <div class="container">
      <div class="row">
        <div class="col-md-12">
            <div class="form-group text-left">
              <br/>
              <input  type="text" class="form-control input-vr" id="nameInput" placeholder="Nombre de la nueva playlist">
            </div>
            <div class="form-group text-left">
              <br/>
              <textarea  class="form-control input-vr" id="descriptionInput" placeholder="Descripción..."></textarea>
              <!--<input  type="text" class="form-control input-vr" id="nameInput" placeholder="Nombre de la nueva playlist">-->
            </div>
        </div>
    </div>
    </div>
    </div>`,
      focusConfirm: false,
      confirmButtonText: "CREAR PLAYLIST",
      confirmButtonColor: "#4e4e4ee1",
      showCancelButton: true,
      cancelButtonText: "CANCELAR",
      cancelButtonColor: "rgb(35, 35, 35)",
      preConfirm: () => {
        return [
          document.getElementById("nameInput"),
          document.getElementById("descriptionInput"),
        ];
      },
    });

    if (formValues) {
      const name = formValues[0].value;
      if (name) {
        const playList: IPlayList = {
          playlist_name: name,
          description: formValues[1].value + " ",
          genres: [],
          tracks: [],
          user_id: this.authS.getCurrentUserInformation().user_id,
        };

        const subx = this.addPlayList(playList).subscribe((data) => {
          this.Toast.fire({
            type: "success",
            title: "Bien! tu playlist ha sido creada, disfrútala.",
          });
          this.getPlayListsByUser(
            this.authS.getCurrentUserInformation().user_id,
            "my-play-lists"
          );
          subx.unsubscribe();
        });
      }
    }
  }

  /**
   * 1.0
   * @param idP PlayListID
   * @param idT TrackID
   */
  public deleteTrack(idP: string, idT: string) {
    // console.log(idP, idT);
    // console.log(this.currentPlayList.playlist_id, this.currentTrack.track_id);

    Swal.fire({
      background: "rgba(41, 40, 40,0.98)",
      backdrop: "rgba(81, 80, 80, 0.279)",
      title: "Confirmación",
      text: "¿Estás seguro de que quieres eliminar esta rola?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgba(52, 52, 52, 0.823)",
      cancelButtonColor: "rgba(52, 52, 52, 0.323)",
      confirmButtonText: "¡Sí, bórralo!",
    }).then((result) => {
      if (result.value) {
        this.removeTrackOfPlayList(idP, idT)
          .toPromise()
          .then((e) => {
            this.removeTrackFromPlayListLive(idT);
            this.Toast.fire({
              type: "success",
              title: "Rola eliminada!",
            });
          })
          .catch((err) => {});
      }
    });
  }

  /**
   * v1.0
   */
  public removeTrackFromPlayListLive(idTrack: string) {
    this.currentPlayList.tracks.forEach((e) => {
      if (e.track_id === idTrack) {
        const index = this.currentPlayList.tracks.indexOf(e);
        if (index > -1) {
          this.currentPlayList.tracks.splice(index, 1);
        }
        return;
      }
    });
  }

  /**
   *  v1.0
   *  Delete user playlist by id.
   * @param id : Id of playlist to remove
   */
  deletePlayList(id: string): void {
    Swal.fire({
      background: "rgba(41, 40, 40,0.98)",
      backdrop: "rgba(81, 80, 80, 0.279)",
      title: "Confirmación",
      text: "¿Estás seguro de que quieres eliminar este playlist?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgba(52, 52, 52, 0.823)",
      cancelButtonColor: "rgba(52, 52, 52, 0.323)",
      confirmButtonText: "¡Sí, bórralo!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.removePlayList(id)
          .toPromise()
          .then((e) => {
            this.Toast.fire({
              type: "success",
              title: "Playlist eliminada!",
            });
            this.getPlayListsByUser(
              this.authS.getCurrentUserInformation().user_id,
              "my-play-lists"
            );
          })
          .catch((err) => {
            this.Toast.fire({
              type: "error",
              title: "Ha ocurrido un error!",
            });
          });
      }
    });
  }

  /** SEARCH METHODS */

  /**
   * v1.0
   */
  async doSearchInBackgroundMode(query?: string, routing: boolean = true) {
    console.log("DO SEARCH -> with " + query);
    this.isLoadingSearchItems = true;
    this.searchQuery = this.searchQuery
      ? this.searchQuery
      : this.global.getLastSeacrh();
    if (this.authS.getCurrentUserInformation() == null) {
      // this.getSearchResultService(this.searchQuery).toPromise().then(e => {
      //   this.buildPlayListSearchResult(e.tracks);
      //   this.clearSearch();
      //   this.global.setLastSearch(this.searchQuery);
      //   this.router.navigate(['/search'], { queryParams: { query: query } });
      //   this.isLoadingSearchItems = false;
      // }).catch(err => {
      //   //console.log(err);
      // });
      const searchResult = await this.getSearchResultInPublicMode(
        this.searchQuery
      ).toPromise(); //.then(searchResult => {
      await this.completeSearch(searchResult, routing, this.searchQuery);
      // this.clearSearch();
      // this.buildPlayListsSearchResult(e);
      // this.global.setLastSearch(this.searchQuery);
      // if(routing){
      //   this.router.navigate(['/search'], { queryParams: { query: query } });
      // }
      // this.isLoadingSearchItems = false;
      //this.completeSearch(searchResult,routing,this.searchQuery);
      // }).catch(err => {
      //   //console.log(err);
      // });
    } else {
      // this.getSearchResultAndUserService(this.searchQuery, this.authS.getCurrentUserInformation().user_id).toPromise().then(e => {
      //   this.buildPlayListSearchResult(e.tracks);
      //   this.global.setLastSearch(this.searchQuery);
      //   this.router.navigate(['/search'], { queryParams: { query: query } });
      //   this.isLoadingSearchItems = false;
      // }).catch(err => {
      //   //console.log(err);
      // });

      const searchResult = await this.getSearchResultInAuthMode(
        this.searchQuery,
        this.authS.getCurrentUserInformation().user_id
      ).toPromise(); //
      await this.completeSearch(searchResult, routing, this.searchQuery);
      //.then(searchResult=>{
      // console.log('====================================');
      // console.log(searchResult);
      // console.log('====================================');
      // this.clearSearch();
      // this.buildPlayListsSearchResult(searchResult);
      // this.global.setLastSearch(this.searchQuery);
      // if(routing){
      //   this.router.navigate(['/search'], { queryParams: { query: query } });
      // }
      // this.isLoadingSearchItems = false;
      //this.completeSearch(searchResult,routing,this.searchQuery);
      // }).catch(err=>{

      // });
    }
  }

  async completeSearch(
    searchResult: SearchResult,
    routing: boolean,
    query: string
  ) {
    this.clearSearch();
    this.buildPlayListsSearchResult(searchResult);
    this.global.setLastSearch(this.searchQuery);
    if (routing) {
      this.router.navigate(["/search"], { queryParams: { query: query } });
    }
    this.isLoadingSearchItems = false;
    return true;
  }

  /**
   *   @param tracks
   *   @version 1.0
   *   @deprecated
   */
  buildPlayListSearchResult(tracks: ITrack[]) {
    this.searchPlayListResult = {
      description: "Disfruta de lo que hemos encontrado para ti",
      genres: [],
      image: "",
      tracks: tracks,
      playlist_id: "",
      playlist_name:
        "Relacionados con " +
        (this.searchQuery ? this.searchQuery : " la última busqueda"),
    };
  }

  buildPlayListsSearchResult(search: SearchResult) {
    const makeSearchPlayList = (
      tracks: ITrack[],
      section: string
    ): IPlayList => {
      return {
        description: "Disfruta de lo que hemos encontrado para ti",
        genres: [],
        image: "",
        tracks: tracks,
        playlist_id: "",
        playlist_name: `${section} relacionados con ${
          this.searchQuery ? this.searchQuery : " la última busqueda"
        }`,
      };
    };

    if (search.tracks) {
      this.searchPlayListProgramsResult = makeSearchPlayList(
        search.tracks.programs,
        "Programas"
      );
      this.searchPlayListConcertsResult = makeSearchPlayList(
        search.tracks.concerts,
        "Conciertos"
      );
      this.searchPlayListVideosResult = makeSearchPlayList(
        search.tracks.videos,
        "Videos"
      );
      // console.log('====================================');
      // console.log([this.searchPlayListConcertsResult,this.searchPlayListProgramsResult,this.searchPlayListVideosResult]);
      // console.log('====================================');
    }
  }
  /**
   * v1.0
   */
  clearSearch() {
    const cleaner = (playList: IPlayList) => {
      try {
        playList.tracks.length = 0;
        playList = null;
      } catch (e) {}
    };
    cleaner(this.searchPlayListResult);
    cleaner(this.searchPlayListConcertsResult);
    cleaner(this.searchPlayListProgramsResult);
    cleaner(this.searchPlayListVideosResult);
  }

  /** END SEARCH METHODS */
  public checkSession(user_id: any, session_id: string): Observable<any> {
    return this.http.get<any>(
      API.dev.url +
        API.dev.TemplatePaths.SESSIONS_CHECK +
        user_id +
        "/" +
        session_id,
      this.httpOptions
    );
  }

  /**
   * V2 Get tracks by show
   */
  public getTracksShow(id: string, lastKey: string): Observable<any> {
    return this.http.get<any>(
      API.dev.url + API.dev.TemplatePaths.GET_SHOWS + "/" + id + "/" + lastKey,
      this.httpOptions
    );
  }
}
