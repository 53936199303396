import { AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login-v2';

export function getAuthServiceConfigs() {
  const config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider('352284678730016')
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider('516502332521-09t5d3eo22kvrfp7onrhtr3dgh2jpj6q.apps.googleusercontent.com')
      },
    ]
  );
  return config;
}
