import { PlayListControlService } from './../../../services/play-list-control.service';
import { SearchResultComponent } from './../../../modules/vr-search/components/search-result/search-result.component';
import { Subscription } from 'rxjs';
import { SidenavComponent } from './../sidenav/sidenav.component';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { VideoRolaComponent } from '../video-rola/video-rola.component';
import { IUser } from '../../../models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public currentUser: IUser = null;

  @Input() sideBar: SidenavComponent;
  @Input() videoRola: VideoRolaComponent;
  private userSubscription: Subscription;
  private search: Subscription;

  click() {
    this.sideBar.toggle();
    this.videoRola.toggle();
  }

  constructor(
    private play: PlayListControlService,
    private authService: AuthenticationService,
    private router: Router,
    private sr: SearchResultComponent) {
    this.userSubscription = this.authService.currentUserAuth.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    this.currentUser = this.authService.getCurrentUserInformation();
  }


  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }

  toSearch(query: string) {
    // this.sr.search_query = query;
    this.play.searchQuery = query;
    this.play.doSearchInBackgroundMode(query);
    // this.sr.backgroundSearch();
    // this.search = this.sr.searchCompleted.subscribe(e => {
    //   console.log('Search completed in header ' + e);
    //   // this.router.navigate(['/search', query], { queryParams: { t: e } });
    //   //this.router.navigate(['/search'], { queryParams: { query: query } });
    // });
  }

  toHome() {
    this.router.navigateByUrl('/');
  }


  ngOnDestroy(): void {

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.search) {
      this.search.unsubscribe();
    }
  }

}
