import { GlobalsService } from './../../../../services/globals.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
//import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//mport { DeviceDetectorService } from 'ngx-device-detector';
import Swal, { SweetAlertType } from 'sweetalert2';

@Component({
  selector: 'app-sign-in-vr',
  templateUrl: './sign-in-vr.component.html',
  styleUrls: ['./sign-in-vr.component.css']
})
export class SignInVrComponent implements OnInit {

  loginPTVForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  closeResult: string;
  deviceInfo = null;
  statusConditions = false;

  typeDevice: string;
  os: string;
  device: string;

  constructor(
    private authentication: AuthenticationService,
    private formBuilder: FormBuilder,
    private global: GlobalsService,
   // private deviceService: DeviceDetectorService,
  ) {
  }

  ngOnInit() {
    this.loginPTVForm = this.formBuilder.group({
      emailLoginPTV: ['', [Validators.required, Validators.email]],
      passwordLoginPTV: ['', Validators.required]
    });

    // Implement a ServiceWorker
    // this.deviceInfo = this.deviceService.getDeviceInfo();
    // if (this.deviceService.isMobile()) {
    //   this.typeDevice = 'Movil';
    // }

    // if (this.deviceService.isTablet()) {
    //   this.typeDevice = 'Tablet';
    // }

    // if (this.deviceService.isDesktop()) {
    //   this.typeDevice = 'Desktop';
    // }

    this.os = "Chrome";
    this.device = "unknown";
    this.typeDevice = 'Desktop';
    //console.log(this.os);
  }


  get form() { return this.loginPTVForm.controls; }

  public onSubmitLogin(): void {
    this.submitted = true;
    if (this.loginPTVForm.invalid) {
      this.showMessage('Ups!', 'Parece que alguno de tus datos no es correcto. Revisa e intenta de nuevo.', 'warning');
      return;
    } else {
      this.loading = true;
      const tempSubsLogin = this.authentication.sigInTVP(this.form.emailLoginPTV.value, this.form.passwordLoginPTV.value)
        .subscribe(resu => {
          console.log(resu);
          if (resu.data.result.login === true) {
            const tempSubsGetUser = this.authentication.getUserInformation(resu.data.result.user_id).subscribe(
              data => {
                // check by id
                const tempSubsCheckSession = this.authentication.checkSession(resu.data.result.user_id, 'MEGACABLE')
                  .subscribe(
                    success => {
                      this.loading = false;
                      if (success.data.result === true) {
                        this.authentication.setCurrentProviderAuth('VR-TVP');
                        this.authentication.setCurrentUserInformation(data.user);

                        //console.log('->> ', data.user);
                        this.authentication.registerInOneSignal(data.user.email);
                        // const OneSignal = window['OneSignal'] || [];
                        // OneSignal.push(function () {
                        //   OneSignal.sendTags({
                        //     correo: data.user.email,
                        //   }).then(function (tagsSent) {
                        //     OneSignal.setEmail(data.user.email);
                        //   });
                        // });

                        const tempSubsSession = this.authentication.getSession(data.user.user_id, this.os, this.device, this.typeDevice)
                          .subscribe(success_ => {
                            this.authentication.setCurrentSession(
                              {
                                id: success_.data.session.session_id,
                                user: success_.data.session.user,
                                os: success_.data.session.os,
                                device: success_.data.session.device,
                                type_device: success_.data.session.type_device,
                                created_at: success_.data.session.creation_date,
                              }
                            );
                            tempSubsSession.unsubscribe();
                          }, error => { });
                        this.global.redirect();
                      } else if (success.data.result === false) {
                        let inputOptions = {};
                        for (var i = 0; i < success.data.sessions.length; i++) {
                          inputOptions[success.data.sessions[i].session_id + '-' + success.data.sessions[i].user] = 'Tipo:' + success.data.sessions[i].device_type + ' Fecha:' + success.data.sessions[i].creation_date.split(" ")[0] + ' Dispositivo:' + success.data.sessions[i].device;
                        }
                        this.showMessageSessions(
                          'warning', inputOptions);
                      }
                      tempSubsCheckSession.unsubscribe();
                    },
                    error => {
                      this.loading = false;
                      // console.log('ERROR GET USER SESSION FROM MEGACABLE');
                      // console.log(error);
                    }
                  );
                tempSubsGetUser.unsubscribe();
              },
              error => {
                this.loading = false;
                tempSubsGetUser.unsubscribe();
                // console.log('ERROR GET USER INFORMATION FROM MEGACABLE ACCOUNT');
                // console.log(error);
              }
            );

          } else if (resu.data.result.login === false) {
            this.showMessage('Usuario/Contraseña incorrectos!',
              'Parece que alguno de tus datos no es correcto. Revisa e intenta de nuevo.', 'error');
          } else {
            this.showMessage('Ups!', 'Error con el proveedor, intentelo más tarde!', 'error');
          }
          this.loading = false;
          tempSubsLogin.unsubscribe();
        }, error => {
          this.loading = false;
          this.showMessage('Usuario/Contraseña incorrectos!',
            'Parece que alguno de tus datos no es correcto. Revisa e intenta de nuevo.', 'error');
        });
    }

  }


  showMessage(title: string, content: string, type: SweetAlertType) {
    Swal.fire({
      type: type,
      title: title,
      text: content,
      background: 'rgba(0,0,0,0.9)',
      confirmButtonColor: 'rgba(22,22,22,0.9)',
      timer: 4500
    });
  }

  showMessageSessions(type: SweetAlertType, inputOptions: {}) {
    Swal.fire({
      type: type,
      title: 'Ups! Has llegado al límite de dispositivos autorizados. Cierra sesión en alguno e inténtalo de nuevo en este.',
      input: 'select',
      inputOptions: inputOptions,
      inputPlaceholder: 'Dispositivos Activos',
      inputValidator: (value) => {
        return new Promise((resolve) => {
          let keys = value.split('-');
          let session_id = keys[0];
          let user_id = keys[1];
          this.authentication.closeSelectedSession(user_id, session_id).subscribe(
            data => {
              console.log(data);
              this.showMessage('Éxito', 'Iniciando sesión en este dispositivo.', 'success');
              this.onSubmitLogin();
            }, error => {
              resolve('Ocurrió un problema al cerrar sesión de manera remota, favor de intentar de nuevo.');
            }
          );
        })
      },
      background: 'rgba(0,0,0,0.9)',
      confirmButtonColor: 'rgba(22,22,22,0.9)',
      confirmButtonText: 'Seleccionar',
    });
  }
}
