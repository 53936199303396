import { Component, OnInit } from '@angular/core';
import { GlobalsService } from './../../../services/globals.service';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'app-carousel-video',
  templateUrl: './carousel-video.component.html',
  styleUrls: ['./carousel-video.component.css']
})
export class CarouselVideoComponent implements OnInit {


  constructor(
    public globals: GlobalsService,
    public carouselOne: NguCarouselConfig
  ) { }

  ngOnInit() {
    this.carouselOne = {
      grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
      slide: 1,
      speed: 250,
      loop: true,
      velocity: 0,
      touch: true,
      point: {
        visible: true,
        hideOnSingleSlide: true
      }
    };
  }

}
