//8dcaeosk1a.execute-api.us-east-1.amazonaws.com/development/aldea-users-api/homescreen/new
https: const API = {
  local: {
    url: "https://8dcaeosk1a.execute-api.us-east-1.amazonaws.com",
  },
  dev: {
    url: "https://8dcaeosk1a.execute-api.us-east-1.amazonaws.com",
    apple_callback: "https://www.videorola.com/login",
    TemplatePaths: {
      USERS: "/development/aldea-users-api/users",
      HOME_SCREEN: "/development/aldea-users-api/homescreen",
      HOME_SCREEN_NEW: "/development/aldea-users-api/homescreen/new",
      GET_SHOWS: "/development/aldea-users-api/tracks/programs",
      GET_ALL_SHOWS: "/development/aldea-users-api/tracks/programs/list",
      SESSIONS: "/development/aldea-users-api/users/session/",
      SESSIONS_CHECK: "/development/aldea-users-api/sessions/",
      LOGIN_MC: "/development/aldea-users-api/login/megacable",
      COLLECTIONS: "/development/aldea-users-api/collections/user/1",
      PLAYLISTS: "/development/aldea-users-api/playlists",
      MAIN_ADVERTISING: "/development/aldea-users-api/advertising/main",
      PRODUCTION: "/development/aldea-users-api/epg",
      COMMENTS: "/development/aldea-users-api/tracks/comments",
      COMMENT: "/development/aldea-users-api/comments",
      USER_PLAYLISTS: "/development/aldea-users-api/collections/user/",
      NOTIFICATIONS: "/development/aldea-users-api/notifications",
      REACTIONS: "/development/aldea-users-api/reactions",
      SEARCH: "/development/aldea-users-api/search/",
      FULL_SEARCH: "/development/aldea-users-api/search/full",
      RECOMMEND: "/development/aldea-users-api/collections/recommended",
      CAROUSEL: "/development/aldea-users-api/collections/carrusel",
      TRACKS: "/development/aldea-users-api/tracks/",
      GET_RECOMENDED_LIST:"/development/aldea-users-api/new-playlist/",
      LIVE: "/development/aldea-users-api/channels/",
      SUPPORT: "/development/aldea-users-api/support/request",
      COUNT_AD: "/development/aldea-users-api/advertising/clicks/",
      USER_IMG: "/development/aldea-users-api/users/picture",
      USER_CODE: "/development/aldea-users-api/codes/",
      VALID_PROMOTIONS: "/development/aldea-users-api/paids/promotions/",
      VR_PACKAGES: "/development/aldea-users-api/packages/web/",
      CHECK_SUBSCRIPTION: "/development/aldea-users-api/users/suscription",
      CONFIRM_PURCHASE: "/development/aldea-users-api/suscription/web",
      CARD_CONEKTA: "/development/aldea-users-api/paids/orders/cards/",
      OXXO_CONEKTA: "/development/aldea-users-api/paids/orders/oxxo/",
      TERMS_VERIFICATION: "/development/aldea-users-api/new-terms-and-policies",
      PRIVATE_SEARCH: '/development/aldea-users-api/new-search/private',
      PUBLIC_SEARCH: '/development/aldea-users-api/new-search/public',
    },
  },
  prod: {
    IP_LOCATION: "http://ip-api.com/json",
    ACCESS_TOKEN: "/development/aldea-users-api/security/token",
    GEOBLOCKING:
      "https://8dcaeosk1a.execute-api.us-east-1.amazonaws.com/development/aldea-users-api/geoblocking/ip/",
    PUBLIC_IP: "https://jsonip.com",
  },
};

export default API;

