import { Router } from '@angular/router';
import { IGridItem } from './../../../models/IGridItem';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-media-grid',
  templateUrl: './media-grid.component.html',
  styleUrls: ['./media-grid.component.css']
})
export class MediaGridComponent implements OnInit {

  @Input() gridItems: IGridItem[];

  constructor(
    private router: Router
  ) {


  }

  ngOnInit() {
  }

  toPlayer(i: number = 0) {
    this.router.navigate
      (['/watch', 'last-moment'], { queryParams: { l: 'grid', t: this.gridItems[i].track_id } });
  }

}
