const awsmobile = {
  'aws_project_region': 'us-east-1',
  'aws_content_delivery_bucket': 'videorola-webapp',
  'aws_content_delivery_bucket_region': 'us-east-1',
  'aws_content_delivery_url': 'https://www.videorola.com',
  'aws_cognito_identity_pool_id': 'us-east-1:5edda653-1a2e-42ca-8d64-0f38a2d61146',
  'aws_cognito_region': 'us-east-1',
  'aws_user_pools_id': 'us-east-1_QjqGXL71K',
  'aws_user_pools_web_client_id': '2t871avud80ua6f0ivnrgn00p1'
};
export default awsmobile;
