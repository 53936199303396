import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'app-advertising',
  templateUrl: './advertising.component.html',
  styleUrls: ['./advertising.component.css']
})
export class AdvertisingComponent implements OnInit, OnDestroy {


  @Input() url: string;
  @Input() imageUrl: string;
  @Input() description: string;
  @Input() title: string;
  ads = [];
  constructor(public carouselOne: NguCarouselConfig) {

  }

  ngOnInit() {
    this.ads.push(this.imageUrl)
    this.carouselOne = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 250,
      loop: true,
      velocity: 0.9,
      touch: false,
      point: {
        visible: true,
      },
      easing: 'cubic-bezier(0, 0, 0.2, 1)'
    };
  }


  launch() {
    window.open(this.url, '_blank');
  }

  ngOnDestroy(): void {
    this.ads = null;
  }
}
