// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mediaMelon: "5690799380",
  domain: "videorola.com",
  bitmovinVersion: "8.15.2",
  bitmovinBrand: "bitmovinjs",
  bitmovinModel: "web player implementation",
  firebase: {
    apiKey: "AIzaSyAIBad394_U95Yq97lmhZEbHHfB87dW4Xk",
    authDomain: "--",
    databaseURL: "--",
    projectId: "videorola-4e33d",
    storageBucket: "videorola-4e33d.appspot.com",
    messagingSenderId: "480362569154",
    appId: "--",
    measurementId: "--",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
