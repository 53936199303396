import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'icon'
})
export class IconPipe implements PipeTransform {

  transform(type: string): string {
    let url = 'assets/image/';
    switch (type) {
      case 'concerts':
        url = url.concat('concerts.png');
        break;
      case 'own':
        url = url.concat('own.png');
        break;
      case 'programmes':
        url = url.concat('programs.png');
        break;
      case 'publiccontent':
          url = url.concat('programs.png');
          break;
      case 'recommended':
        url = url.concat('vrplaylists.png');
        break;
      default:
        url = url.concat('vr_logo.png');
        break;
    }
    return url;
  }

}
