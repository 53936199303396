import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { PaymentService } from "./../../../services/payment.service";
import { IPromotion } from "./../../../models/IPromotion";
import { map } from "rxjs/operators";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import Swal, { SweetAlertResult } from "sweetalert2";
import { Location } from "@angular/common";
import { AuthenticationService } from "./../../../core/services/authentication.service";
import { PlayListControlService } from "./../../../services";

class Base {
  success(str: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title: "Ok",
      text: str,
      type: "success",
      showCancelButton: false,
      confirmButtonColor: "rgba(62, 62, 62, 0.911)",
      cancelButtonColor: "rgba(255, 255, 255, 0.1)",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar!",
      background: "rgba(0,0,0,0.9)",
      backdrop: "rgba(0,0,0,0.8)",
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      timer: 3000,
    });
  }

  error(msg: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title: "Ups!",
      text: msg,
      type: "error",
      showCancelButton: false,
      confirmButtonColor: "rgba(62, 62, 62, 0.911)",
      cancelButtonColor: "rgba(255, 255, 255, 0.1)",
      confirmButtonText: "Ok!",
      cancelButtonText: "Cancelar!",
      background: "rgba(0,0,0,0.9)",
      backdrop: "rgba(0,0,0,0.8)",
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      timer: 6500,
      footer: `<small>Revisa tus datos e intentalo de nuevo.</small>`,
    });
  }
}

// Payment picker
@Component({
  selector: "app-card-payment-method",
  templateUrl: "./card-payment-method.component.html",
  styleUrls: ["./payment-flow.component.css"],
})
export class CardPaymentMethodComponent extends Base implements OnInit {
  @Input() track: string;
  @Input() user: string;
  @Input() promotion: IPromotion;
  @Input() currency: string;

  stringTitle = "Tarjeta de crédito";
  cardForm: FormGroup;
  submitted = false;
  payed = false;
  resultPayment: any;

  constructor(
    public active: NgbActiveModal,
    private formBuilder: FormBuilder,
    private pay: PaymentService
  ) {
    super();
  }

  ngOnInit(): void {
    this.cardForm = this.formBuilder.group({
      name: ["", Validators.required],
      cardNumber: ["", Validators.required],
      cvcCard: ["", Validators.required],
      monthCard: ["", Validators.required],
      yearCard: ["", Validators.required],
    });
  }

  get f() {
    return this.cardForm.controls;
  }

  gotoPay() {
    this.submitted = true;

    if (this.cardForm.invalid) {
      return;
    }
    const Conekta = window["Conekta"] || [];
    //Conekta.setPublicKey('key_NziPTwbW3s1FZJuWmdKdtkw');
    Conekta.setPublicKey("key_aqZsfrioWdj8gCiDTsx9qWA");
    Conekta.setLanguage("es");
    const tokenParams = {
      card: {
        number: this.f.cardNumber.value,
        name: this.f.name.value,
        exp_year: this.f.yearCard.value,
        exp_month: this.f.monthCard.value,
        cvc: this.f.cvcCard.value,
      },
    };

    Conekta.Token.create(
      tokenParams,
      (token) => {
        const t = this.pay
          .applyAndConfirm({
            user_id: atob(this.user),
            package_id: this.promotion.package_id,
            transaction_id: "",
            price_type: this.promotion.price_type,
            price:
              this.currency === "MXN"
                ? this.promotion.price_mxn
                : this.promotion.price,
            payment_type: "WEB_APP",
            username: this.f.name.value,
            currency: this.currency,
            token: token,
          })
          .subscribe(
            (res) => {
              console.log("Reponse create order: ", res);
              this.resultPayment = res;
              this.payed = true;
              this.continue(res, true);
              this.stringTitle = "Felicidades";
              t.unsubscribe();
            },
            (err) => {
              err.message = err.error.order.details;
              console.log("Reponse order error: ", err);
              this.continue(err, false);
              t.unsubscribe();
            }
          );
      },
      (e) => {
        console.log("Error al hacer el pago", e);
        this.continue(e, false);
      }
    );
  }

  close() {
    this.active.close({ reason: "payed", data: this.resultPayment.order });
  }
  continue(data: any, success: boolean) {
    if (success) {
      this.success("El pago fue exitoso");
    } else {
      this.error(data.message);
    }
  }
}

// Payment picker
@Component({
  selector: "app-oxxo-payment-method",
  templateUrl: "./oxxo-payment-method.component.html",
  styleUrls: ["./payment-flow.component.css"],
  styles: [
    `
      h3 {
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .opps {
        background-color: #f8f9fa;
        width: 496px;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0 45px;
        margin: 40px auto;
        overflow: hidden;
        border: 1px solid #b0afb5;
        font-family: "Open Sans", sans-serif;
        color: #4f5365;
      }

      .opps-reminder {
        position: relative;
        top: -1px;
        padding: 9px 0 10px;
        font-size: 11px;
        text-transform: uppercase;
        text-align: center;
        color: #ffffff;
        background: #000000;
      }

      .opps-info {
        margin-top: 26px;
        position: relative;
      }

      .opps-info:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
      }

      .opps-brand {
        width: 45%;
        float: left;
      }

      .opps-brand img {
        max-width: 150px;
        margin-top: 2px;
      }

      .opps-ammount {
        width: 55%;
        float: right;
      }

      .opps-ammount h2 {
        font-size: 36px;
        color: #000000;
        line-height: 24px;
        margin-bottom: 15px;
      }

      .opps-ammount h2 sup {
        font-size: 16px;
        position: relative;
        top: -2px;
      }

      .opps-ammount p {
        font-size: 10px;
        line-height: 14px;
      }

      .opps-reference {
        margin-top: 14px;
      }

      h1 {
        font-size: 27px;
        color: #000000;
        text-align: center;
        margin-top: -1px;
        padding: 6px 0 7px;
        border: 1px solid #b0afb5;
        border-radius: 4px;
        background: #f8f9fa;
      }

      .opps-instructions {
        margin: 32px -45px 0;
        padding: 32px 45px 45px;
        border-top: 1px solid #b0afb5;
        background: #f8f9fa;
      }

      ol {
        margin: 17px 0 0 16px;
      }

      li + li {
        margin-top: 10px;
        color: #000000;
      }

      a {
        color: #1155cc;
      }

      .opps-footnote {
        margin-top: 22px;
        padding: 22px 20 24px;
        color: #108f30;
        text-align: center;
        border: 1px solid #108f30;
        border-radius: 4px;
        background: #ffffff;
      }
    `,
  ],
})
export class OxxoPaymentMethodComponent extends Base implements OnInit {
  @Input() track: string;
  @Input() user: string;
  @Input() promotion: IPromotion;

  resultOrder: any = null;

  creating = true;

  constructor(public active: NgbActiveModal, private pay: PaymentService) {
    super();
  }

  ngOnInit(): void {
    this.getOrder();
  }

  getOrder() {
    if (this.user && this.promotion) {
      const t = this.pay
        .oxOrder(atob(this.user), this.promotion.package_id)
        .subscribe(
          (oxxo) => {
            // console.log('Result oxxo: ', oxxo);
            this.success("Orden de pago generada con exito");
            this.creating = false;
            this.resultOrder = oxxo;
            t.unsubscribe();
          },
          (e) => {
            t.unsubscribe();
            // console.log('Result oxxo error: ', e);
            this.creating = false;
            this.error("Error interno, vuelve a intentarlo..");
          }
        );
    } else {
      this.error("Error al generar orden de pago, vuelve a intentarlo..");
    }
  }

  tryPrint() {
    window.print();
  }

  close(reason: string) {
    if (reason === "payed") {
      if (!this.creating && !this.resultOrder) {
        this.active.close("");
      } else {
        this.active.close("payed");
      }
    } else {
      this.active.close("close");
    }
  }
}

// Promotion selector
@Component({
  selector: "app-promotion-selector",
  templateUrl: "./promotion-selector.components.html",
  styleUrls: ["./payment-flow.component.css"],
})
export class PromotionSelectorComponent implements OnInit, OnDestroy {
  @Input() track: string;
  @Input() user: string;
  @Input() origin: string;
  isLoading = true;
  promotions: IPromotion[];
  status: string;
  public currency;

  constructor(
    public active: NgbActiveModal,
    private pay: PaymentService,
    private modal: NgbModal,
    private auth: AuthenticationService,
    private playControl: PlayListControlService
  ) {}

  ngOnInit() {
    this.getPromotionsByTrack();
  }

  gotoOxxo(p: IPromotion) {
    const ref = this.modal.open(OxxoPaymentMethodComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    ref.componentInstance.track = this.track;
    ref.componentInstance.user = this.user;
    ref.componentInstance.promotion = p;
    ref.result.then((v) => {
      this.closeCancel(v);
    });
  }

  gotoCard(p: IPromotion) {
    const ref = this.modal.open(CardPaymentMethodComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    ref.componentInstance.track = this.track;
    ref.componentInstance.user = this.user;
    ref.componentInstance.promotion = p;
    ref.componentInstance.currency = this.currency;
    ref.result.then((v) => {
      if (v.reason != null) {
        if (this.auth.getCurrentUserInformation() != null) {
          const t = this.pay
            .codeForUser(
              v.data.string_code,
              this.auth.getCurrentUserInformation().user_id
            )
            .subscribe(
              (data) => {
                this.closeCancel(v.reason);
              },
              (e) => {
                this.closeCancel(v.reason);
              }
            );
        }
      } else {
        this.closeCancel(v);
      }
    });
  }

  getPromotionsByTrack() {
    const temp = this.pay
      .promotions(atob(this.user))
      .pipe(map((e: any) => e))
      .subscribe(
        (promotions) => {
          this.promotions = promotions.packages;
          this.currency = promotions.currency;
          this.isLoading = false;
          temp.unsubscribe();
        },
        (err) => {
          temp.unsubscribe();
        }
      );
  }
  gotoPay(p: IPromotion) {
    if (p.platform === "mobile") {
      return;
    }

    this.gotoCard(p);
  }
  requestPromotionCode() {
    this.playControl.requestCode();
  }
  showTerms() {
    window.open("https://www.videorola.com/terminos-de-uso", "_blank").focus();
  }
  closeCancel(value: string) {
    switch (value) {
      case "closePromotionsModal":
        this.active.close("closeModal");
        break;
      case "payed":
        this.active.close("payed");
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {}
}

// Base component
@Component({
  selector: "app-payment-flow",
  templateUrl: "./payment-flow.component.html",
  styleUrls: ["./payment-flow.component.css"],
})
export class PaymentFlowComponent implements OnDestroy {
  trackParam = "";
  userParam = "";
  origin = "";
  subscriptions: Subscription[];
  isLoading = false;

  constructor(
    private location: Location,
    private _route: ActivatedRoute,
    private router: Router,
    private modal: NgbModal
  ) {
    this.subscriptions = [];
    this.subscriptions.push(
      this._route.queryParams.subscribe((params) => {
        this.trackParam = params["t"];
        this.userParam = params["ui"];
        this.origin = params["origin"];
        if (this.trackParam && this.userParam && this.origin) {
          this.showPromotions();
        } else {
          this.router.navigateByUrl("/main");
        }
      })
    );
  }

  showPromotions() {
    const ref = this.modal.open(PromotionSelectorComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    ref.componentInstance.track = this.trackParam;
    ref.componentInstance.user = this.userParam;
    ref.componentInstance.origin = this.origin;

    ref.result.then((reason) => {
      if (this.origin === "web-app") {
        this.goBack();
      } else {
        this.close();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((element) => {
      if (element) {
        element.unsubscribe();
      }
    });
  }

  goBack() {
    this.isLoading = true;
    this.router.navigateByUrl("/main");
    //this.location.back();
  }

  close() {
    window.focus();
    self.opener = this;
    self.close();
    window.open("", "_self").close();
    window.open("", "_self", "");
    window.close();
    window.open("", "_parent", "");
    window.close();
    this.router.navigateByUrl("/main");
  }
}
