import { Router } from "@angular/router";
import { IPlayList } from "./../../../models/IPlayList";
import { Component, OnInit, Input } from "@angular/core";
import { NguCarouselConfig } from "@ngu/carousel";
import Swal from "sweetalert2";

@Component({
  selector: "app-carousel-playlist",
  templateUrl: "./carousel-playlist.component.html",
  styleUrls: ["./carousel-playlist.component.css"],
})
export class CarouselPlaylistComponent implements OnInit {
  @Input() origin: string;
  @Input() counter: string;
  @Input() playLists: IPlayList[] = [];
  isLoading = true;

  constructor(public carouselOne: NguCarouselConfig, public router: Router) {
    setTimeout(() => {
      console.log(this.origin);
      console.log(this.playLists);
    }, 2500);
  }

  ngOnInit() {
    this.carouselOne = {
      grid: { xs: 1, sm: 2, md: 3, lg: 4, all: 0 },
      slide: 1,
      speed: 250,
      loop: true,
      velocity: 0.9,
      touch: true,
      point: {
        visible: true,
        hideOnSingleSlide: true,
      },
      easing: "cubic-bezier(0, 0, 0.2, 1)",
      // interval: { timing: 4000, initialDelay: 1000 }
    };

    if (
      this.playLists.length >= 5 && // ! chnage to 5
      this.playLists[this.playLists.length - 1].playlist_name !== "last-item"
    ) {
      this.playLists.push({
        tracks: [],
        playlist_name: "last-item",
        genres: [],
        description: "",
      });
    }
    this.isLoading = false;
  }

  toPlayer(i: number = 0) {
    //console.log(this.origin);
    switch (this.origin) {
      case "concerts":
        this.router.navigate(["/watch", "concert"], {
          queryParams: { l: "t_concert", t: this.playLists[i].track_id },
        });
        break;
      case "programmes":
        // this.router.navigate
        //   (['/watch', 'programs'], { queryParams: { l: 't_programs', t: this.playLists[i].track_id } });

        if(this.playLists[i].playlist_name=='last-item'){
          return;
        }
        if (this.playLists[i].tracks.length <= 0) {
          this.showEmptyListToast("¡Programa sin capitulos!");
          return;
        }
        console.log(this.playLists[i]);
        this.router.navigate(["/watch", "programs"], {
          queryParams: { l: this.playLists[i].id, t: this.playLists[i].tracks[0] },
        });
        break;
      case "publiccontent":
        this.router.navigate(["/watch", "publiccontent"], {
          queryParams: { l: "t_programs", t: this.playLists[i].track_id },
        });
        break;
      default:
        if (this.playLists[i].tracks.length > 0) {
          if (this.origin === "own") {
            this.router.navigate(["/watch", "playlist"], {
              queryParams: {
                l: this.playLists[i].playlist_id,
                t: this.playLists[i].tracks[0],
              },
            });
          } else {
            this.router.navigate(["/watch", "playlist-vr"], {
              queryParams: {
                l: this.playLists[i].playlist_id,
                t: this.playLists[i].tracks[0],
              },
            });
          }
        } else {
          if (this.playLists.length - 1 > i || this.playLists.length < 5) {
            this.showEmptyListToast();
          }
        }
        break;
    }
  }

  showEmptyListToast(msg: string = "PlayList Vacia!"): void {
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-right",
      showConfirmButton: false,
      timer: 3000,
      background: "rgba(0,0,0,0.8)",
    });
    Toast.fire({
      type: "warning",
      title: msg,
    });
  }
}
