import { Router } from '@angular/router';
import { IPlayList } from './../../../models/IPlayList';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';


@Component({
  selector: 'app-carousel-search',
  templateUrl: './carousel-search.component.html',
  styleUrls: ['./carousel-search.component.css']
})
export class CarouselSearchComponent implements OnInit {


  @Input() origin: string;
  @Input() carousel_type: string;
  @Input() counter: string;
  @Input() playList: IPlayList;
  @Input() query: string;
  @Output() onplay: EventEmitter<any> = new EventEmitter<any>();
  isLoading = true;

  constructor(public carouselOne: NguCarouselConfig, public router: Router) {
  }

  ngOnInit() {
    this.carouselOne = {
      grid: { xs: 1, sm: 2, md: 3, lg: 4, all: 0 },
      slide: 1,
      speed: 250,
      loop: true,
      velocity: 0.9,
      touch: true,
      point: {
        visible: true,
        hideOnSingleSlide: true
      },
      easing: 'cubic-bezier(0, 0, 0.2, 1)'
      // interval: { timing: 4000, initialDelay: 1000 }
    };

    if (this.playList.tracks.length >= 8 && this.playList.tracks[this.playList.tracks.length - 1].name !== 'last-item') {
      this.playList.tracks.splice(8,this.playList.tracks.length);
      this.playList.tracks.push({ album: '',category_id: this.carousel_type ,mimeType:'',image:'',id: '', fileUrl:'',duration: 0, authors: [], streamingUrl:'',  name: 'last-item', genres: [], description: '', type: "" });
    }

    this.isLoading = false;
  }

  toPlayer(i: number = 0) {
    
    if(i>=8) {
      console.log("mostrar")
      console.log(this.carousel_type)
      this.router.navigate(['watch/play-lists', 'search-related-videos'],{ queryParams: { q: this.query, f:this.carousel_type} });
      return;
    }
    console.log("no mostrar")
    this.onplay.emit({'playList':this.playList,'current': this.playList.tracks[i].track_id });
  }


}
