import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../../../../services/globals.service';

export enum AUTH_VIEWS{
  SIGN_IN,
  SIGN_UP,
  PRE_SIGN,
  PROVIDER_SIGN_IN
}

@Component({
  selector: 'app-auth-form',
  templateUrl: './auth-form.component.html',
  styleUrls: ['./auth-form.component.css']
})
export class AuthFormComponent implements OnInit {

  public tabSignIn = 'active';
  public tabSignUp = '';
  public showLoginProvider = 'invisible';
  public currentView: AUTH_VIEWS = AUTH_VIEWS.SIGN_IN;
  public views = AUTH_VIEWS;

  constructor(private global: GlobalsService) {
  }

  ngOnInit() {
    if(this.global.isBlockBySession){
      this.currentView = this.views.SIGN_IN;
    }
  }


  /**
   * @deprecated
   */
  public showLoginTVProvider() {
    this.showLoginProvider = this.showLoginProvider === 'invisible' ? 'visible' : 'invisible';
  }

  /**
   * @deprecated
   * @param tab
   */
  public setSelectedTab(tab: string): void {
    if (tab === 'LG') {
      this.tabSignIn = 'active';
      this.tabSignUp = '';
    } else {
      this.tabSignUp = 'active';
      this.tabSignIn = '';
    }
  }

  /**
   * showView
   */
  public showView(view: AUTH_VIEWS) {
    this.currentView = view;
    console.log(view);

  }


}
