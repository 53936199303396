import { Component, OnInit } from '@angular/core';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-video-rola',
  templateUrl: './video-rola.component.html',
  styleUrls: ['./video-rola.component.css']
})
export class VideoRolaComponent implements OnInit {


  isOpen = false;
  styleClass: string;

  toggle() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.styleClass = 'mini-bar';
    } else {
      this.styleClass = '';
    }
  }

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string) { }

  onActivate(event: any) {
    document.getElementById('content').scrollTop = 0;
  }


  onDeactivate() {
    document.getElementById('content').scrollTop = 0;
  }

  ngOnInit() {

  }



}
