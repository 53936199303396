import { Subscription } from 'rxjs';
import { GlobalsService } from './../../../services/globals.service';
import { IUser } from './../../../models/IUser';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { PlayListControlService } from '../../../services/play-list-control.service';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit, OnDestroy {

  public currentUser: IUser;
  public active: string;
  public isOpen = false;
  private subscription: Subscription;
  Toast = Swal.mixin({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, background: 'rgba(0,0,0,0.8)' });

  public toggle(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.active = 'active';
      // this.globalService.setStatus(true);
    } else {
      this.active = '';
      // this.globalService.setStatus(false);
    }
  }


  constructor(
    private auth: AuthenticationService,
    private _router: Router,
    public globalService: GlobalsService,
    public playSer: PlayListControlService
  ) {
    this.subscription = this.auth.currentUserAuth.subscribe(x => this.currentUser = x);
    this.toggle();
  }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUserInformation();
  }

  public goToPlayListDetail(i: number = 0) {

    if (this.playSer.playLists[i].tracks.length > 0) {
      this._router.navigate
        (['/watch', 'playlist'],
          { queryParams: { l: this.playSer.playLists[i].playlist_id, t: this.playSer.playLists[i].tracks[0].track_id } });
    } else {
      this.Toast.fire({
        type: 'warning',
        title: 'PlayList Vacia!'
      });
    }

  }


  goToPlayListMenu(menu: number): void {
    this.globalService.currentMenu = menu;
    this._router.navigate(['/watch/play-lists', 'my-play-lists']);
  }

  goToPrivacy() {
    this._router.navigateByUrl('/politicas-de-privacidad');
  }

  goToTerms() {
    this._router.navigateByUrl('/terminos-de-uso');
  }


  deletePlayList(id: string): void {
    this.playSer.deletePlayList(id);
  }

  addNewPlayList(origin: string) {
    this.playSer.addNewPlayList(origin);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }
}
