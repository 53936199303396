import { GlobalsService } from "./../../../../services/globals.service";
import { AmplifyService } from "aws-amplify-angular";
import { AuthenticationService } from "../../../services/authentication.service";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import Swal, { SweetAlertType } from "sweetalert2";
import { AUTH_VIEWS } from "../auth-form/auth-form.component";
import {
  AuthService,
  FacebookLoginProvider,
  GoogleLoginProvider,
} from "angular-6-social-login-v2";
import API from "./../../../../configs/ApisConfig";
declare var AppleID;
declare var dataLayer: any;
@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.css"],
  styles: [
    `
      .dark-modal .modal-content {
        background-color: #292b2c;
        color: white;
      }
      .dark-modal .close {
        color: white;
      }
      .text-light {
        color: rgb(169, 169, 169);
      }
    `,
  ],
})
export class SignUpComponent implements OnInit {
  @Output()
  private changeView = new EventEmitter<number>();
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  errorFormSignUp = false;
  textFormErroSignUp = "";
  statusConditions = false;
  alreadyHaveCodeCheck = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private amplifyService: AmplifyService,
    private authenticate: AuthenticationService,
    private socialAutg: AuthService,
    private global: GlobalsService
  ) {
    this.amplifyService.authStateChange$.subscribe((authState) => {
      if (authState.user === "signedIn") {
        this.router.navigateByUrl("/home");
      }
    });
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: ["", Validators.required],
      familyName: ["-"],
      promotion_code: [""],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(8)]],
    });
  }

  haveCodeClick(event: any) {
    this.alreadyHaveCodeCheck = !this.alreadyHaveCodeCheck;
    if (this.alreadyHaveCodeCheck) {
    }
    console.log(this.alreadyHaveCodeCheck);
  }
  get form() {
    return this.registerForm.controls;
  }

  public onSubmitRegister($event?): void {
    //$event.preventDefault();
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.errorFormSignUp = true;
      let msg =
        "Parece que alguno de tus datos no es correcto. Revisa e intenta de nuevo.";
      if (this.registerForm.get("password").hasError("minlength")) {
        msg = "La contraseña debe tener un mínimo de 8 caracteres!";
      }
      Swal.fire({
        type: "error",
        title: "Ups!",
        text: msg,
        customClass: "back-swal",
        confirmButtonColor: "#2e2e2eea",
      });
    } else {
      if (this.statusConditions === false) {
        //this.launchModal();
        Swal.fire({
          type: "error",
          title: "Ups!",
          text: "Es necesario aceptar Aceptar Terminos de uso y las Politicas de Privacidad.",
          customClass: "back-swal",
          confirmButtonColor: "#2e2e2eea",
        });
      } else {
        this.loading = true;
        const body = {
          email: this.form.email.value,
          name: this.form.name.value,
          promotion_code: this.form.promotion_code.value,
          surnames:
            this.form.familyName.value === ""
              ? " "
              : this.form.familyName.value,
        };

        this.authenticate
          .cognitoSignUp(body.name, body.email, this.form.password.value)
          .then((data) => {
            this.registerForm.reset();
            this.submitted = false;
            this.authenticate.registerUser(body);
          })
          .catch((e) => {
            this.handleErrors(e);
          });
      }
    }
  }

  private handleErrors(e) {
    let message = "Actualmente registro no diponible, intentalo más tarde!";
    try {
      switch (e.code) {
        case "UsernameExistsException":
          message =
            "Ya existe una cuenta con ese usuario, te recomendamos:\nRecuperar tu contraseña ó  confirmar el correo que te enviamos a tu email cuando te registraste.";
          break;
        default:
          break;
      }
    } catch (error) {}

    Swal.fire({
      type: "error",
      title: "Ups!",
      text: message,
      showConfirmButton: true,
      timer: 10000,
      background: "rgba(0,0,0,0.9)",
    });
  }

  launchModal() {
    Swal.mixin({
      showCancelButton: true,
      progressSteps: ["1", "2"],
      background: "rgba(0,0,0,0.9)",
      customClass: "btn-vr",
      confirmButtonColor: "rgba(20,20,20,0.9)",
      cancelButtonColor: "rgba(30,30,30,0.9)",
    })
      .queue([
        {
          html: `
      <div class="container-fluid">
        <div class="row" style="color: rgb(169, 169, 169);">
                <div class="col-md-12" style="height:500px;overflow:scroll;">
                    <h5 class="text-left text-lightx">TERMINOS DE USO.</h5>
                    <p class="text-justify text-lightx">
                        Hola, te compartimos este documento por medio del cual Entretenimiento Satelital, S.A. de C.V. (en lo sucesivo “VIDEOROLA®”) celebran contigo (en lo sucesivo “El Usuario”) los términos y condiciones de uso de una aplicación para dispositivos móviles,
                        compatibles con algunos sistemas iOS y algunos sistemas Android, los cual se componen pero no los limitan a “SmartPhones”, “Tablets”, y “Laptops” al que se denomina en lo siguiente como “LA APLICACION”.
                    </p>
                    <hr>
                    <p class="text-justify text-lightx">
                        Al visitar el sitio o descargar la APP (seas o no “Usuario Prepago”), aceptas y te obligas conforme a los presentes términos y condiciones, incluyendo cualquier modificación futura, además de aceptar la competencia de los tribunales correspondientes al
                        capítulo de “MISCELÁNEOS” de los presentes “TERMINOS DE USO”. VIDEOROLA® puede modificar este Acuerdo en cualquier momento, y en dicha modificación surtirá sus efectos correspondientes al ser incorporados en el presente documento.
                        El uso reiterado de “El Usuario” sobre de “La Aplicación” constituye su aceptación hacia los términos y condiciones aquí plasmados. Si “El Usuario” no está de acuerdo con los presentes términos y condiciones, deberá dejar de utilizar
                        la aplicación material del presente acuerdo.
                    </p>
                    <p class="text-left text-lightx">
                        DEFINICIONES
                    </p>
                    <div class="row">
                        <div class="col-md-12">
                        <h6 class="text-left">LA APLICACION:</h6>
                          <p class="text-justify text-lightx">Elemento descargable propiedad de VIDEOROLA® a dispositivos móviles de la elección de “EL USUARIO”. También sitio de internet www.videorola.com con las mismas funcionalidades en línea del elemento descargable.</p>
                        </div>
                        <div class="space"></div>
                        <div class="col-md-12">
                        <h6 class="text-left">EL USUARIO:</h6>
                          <p class="text-justify text-lightx">Tú, es decir, cualquier persona que utilice “LA APLICACION” ya sea gratuita o Prepago y será identificado mediante el correo electrónico que designe al momento de registrar su cuenta.</p>
                        </div>
                        <div class="space"></div>
                        <div class="col-md-12">
                        <h6 class="text-left">CÓDIGO:</h6>
                          <p class="text-justify text-lightx">Clave de acceso que adquirirá EL USUARIO en las tiendas de autoservicio, y que requerirá “EL USUARIO” para tener acceso a LA APLICACIÓN al momento de registrar su cuenta.</p>
                        </div>
                        <div class="col-md-12">
                        <h6 class="text-left">USUARIO PREPAGO:</h6>
                          <p class="text-justify text-lightx">Cualquier persona que adquiere un CÓDIGO en cualquier tienda de autoservicio o utilice un medio de pago electrónico para acceder a LA APLICACIÓN.</p>
                        </div>
                        <div class="space"></div>
                        <div class="col-md-12">
                        <h6 class="text-left">USUARIO GRATUITO:</h6>
                          <p class="text-justify text-lightx">Cualquier usuario autenticado de Servicios de Televisión de Paga autorizados por VIDEOROLA.</p>
                        </div>
                        <div class="space"></div>
                        <div class="col-md-12">
                        <h6 class="text-left">PASSWORD::</h6>
                          <p class="text-justify text-lightx">Contraseña de acceso que elegirá “EL USUARIO” al momento de registrar su cuenta en LA APLICACIÓN o en el sitio www.videorola.com.</p>
                        </div>
                        <div class="space"></div>
                        <div class="col-md-12">
                        <h6 class="text-left">CUENTA:</h6>
                          <p class="text-justify text-lightx">Es el registro en la base de datos de VIDEOROLA® respecto de los USUARIOS, y la cual se conforma por USUARIO Y PASSWORD y otros datos de PERFIL.</p>
                        </div>
                        <div class="space"></div>
                        <div class="col-md-12">
                        <h6 class="text-left">PERFIL:</h6>
                          <p class="text-justify text-lightx">Conjunto de datos que ingresas para dar de alta tu cuenta y que hacen identificable a un USUARIO</p>
                        </div>
                        <div class="space"></div>
                        <div class="col-md-12">
                        <h6 class="text-left">SERVICIOS DE TERCEROS:</h6>
                          <p class="text-justify text-lightx">Los que se compone de enlaces a otros sitios web, aplicaciones u otros recursos y servicios creados por terceros.</p>
                        </div>
                        <div class="space"></div>
                    </div>
                    <hr/>
                    <strong class="text-left">
                        <h5 class="text-lightx">REGISTRO DE CUENTA</h5>
                    </strong>
                    <p class="text-justify text-lightx">
                        Para tener derecho al uso de LA APLICACION, el USUARIO tendrá que ingresar a la tienda virtual de descarga de aplicaciones según el sistema operativo de su dispositivo y descargar LA APLICACION de manera gratuita, una vez instalado, podrá crear una CUENTA,
                        misma en la que le serán requeridos datos personales para su registro. Una vez ingresados sus datos, para finalizar con el registro, tendrá que elegir un PASSWORD para ingresar a su CUENTA, el cual deberá estar formado por números
                        y letras éste no deberá exceder del número de caracteres definido para estos efectos por VIDEOROLA®.
                    </p>
                    <p class="text-justify text-lightx">
                        Para tener derecho de utilizar “LA APLICACION” deberá contar con la mayoría de edad y estar en pleno uso de sus capacidades de ejercicio. Por lo que, en caso de no contar con dicho requisito previo, está prohibido el uso de “LA APLICACION”, y VIDEOROLA®
                        no se hace responsable de los contenidos que puedan ser considerados como ofensivos o perjudiciales. Todo registro de información que sea enviado para la creación de la cuenta, deberá ser precisa y estar en todo tiempo actualizada.
                        “El Usuario” es responsable por mantener dicha información con carácter de confidencial, por lo que no podrá difundir datos como su número de cuenta o usuario y su PASSWORD. Por lo que es crítico que no comparta su Password con
                        nadie. Y a su vez acepta no utilizar el número de cuenta, usuario o “PASSWORD” de otro “Usuario” en cualquier momento.
                    </p>
                    <hr/>
                    <strong class="text-left">
                        <h5 class="text-lightx">AVISO DE PRIVACIDAD</h5>
                    </strong>
                    <p class="text-justify text-lightx">
                        Para conocer el Aviso de Privacidad, el “USUARIO” podrá ingresar al siguiente link http://www.videorola.com/avisodeprivacidad.htm de VIDEOROLA®.
                    </p>
                    <hr/>
                    <strong class="text-left">
                        <h5 class="text-lightx">TERMINACIÓN</h5>
                    </strong>
                    <p class="text-justify text-lightx">
                        Este acuerdo permanece vigente mientras el “USUARIO” utiliza la “APLICACION”, el cual puede terminar su CUENTA en cualquier momento, bastando enviar un correo electrónico a la cuenta: contacto@videorola.com; VIDEOROLA® puede terminar su CUENTA en cualquier
                        tiempo sin previa notificación, sin obligación de cualquier tipo para la misma. “EL USUARIO” acepta que su CUENTA es personal e intransferible.
                    </p>
                    <p class="text-justify text-lightx">
                        El aviso de terminación que el USUARIO dé a VIDEOROLA® sobre su deseo de cancelar su CUENTA no obliga a esta última a hacer algún tipo de devolución al USUARIO sobre la cantidad que haya erogado por el pago de la APLICACION.
                    </p>
                    <hr/>
                    <strong class="text-left">
                        <h5 class="text-lightx">MODIFICACIONES</h5>
                    </strong>
                    <p class="text-justify text-lightx">
                        VIDEOROLA® se reserva el derecho de modificar en cualquier momento “Los Términos y Condiciones” sobre el uso de la “APLICACION”. Dichas modificaciones a los “Términos y Condiciones” entrarán de inmediato en vigencia. El uso continuado de “LA APLICACION”,
                        se considerará como la aceptación de dichas modificaciones.
                    </p>
                    <hr/>
                    <strong class="text-left">
                        <h5 class="text-lightx">EXCLUSIÓN DE GARANTÍAS; LÍMITES DE RESPONSABILIDAD.</h5>
                    </strong>
                    <p class="text-justify text-lightx">
                        VIDEOROLA® no garantiza ni declara que el uso que “EL USUARIO” haga de “LA APLICACION” será ininterrumpido o que no contendrá errores, y “EL USUARIO” acepta que VIDEOROLA® podrá periódicamente eliminar “LA APLICACION” por un tiempo indeterminado, o cancelar
                        “LA APLICACION” en cualquier momento sin necesidad de notificación alguna.
                    </p>
                    <p class="text-justify text-lightx">
                        EL USUARIO reconoce expresamente que el uso o la incapacidad de usar LA APLICACION quedan bajo su propia responsabilidad. LA APLICACION se entrega "tal como está" y "en la medida en que se encuentre disponible" para su uso, sin garantías de ninguna clase,
                        expresas o tacitas.
                    </p>
                    <p class="text-justify text-lightx">
                        En ningún caso VIDEOROLA®, sus directores, funcionarios, empleados, afiliados, agentes, contratistas, mandatarios, o licenciantes resultarán responsables por daños directos, indirectos, incidentales, punitivos, especiales, o emergentes derivados del uso
                        de “LA APLICACION” o por cualquier otro reclamo relacionado de cualquier manera con el uso que “EL USUARIO” haga de “LA APLICACION”.
                    </p>
                    <p class="text-justify text-lightx">
                        VIDEOROLA® no declara ni garantiza que “LA APLICACION” permanecerán libres de pérdidas, deterioro, ataques (hackers), virus, interferencia, piratería, u otro tipo de penetración de seguridad, VIDEOROLA® niega toda responsabilidad relacionada con lo anteriormente
                        mencionado. “EL USUARIO” será responsable de realizar copias de seguridad de su propio sistema.
                    </p>
                    <hr/>
                    <strong class="text-left">
                        <h5 class="text-lightx">MARCAS.</h5>
                    </strong>
                    <p class="text-justify text-lightx">
                        VIDEOROLA®, el logo de VIDEOROLA®, Video Rola Música para tus ojos®, y otras marcas de VIDEOROLA®, gráficos, y logos utilizados en relación con LA APLICACION son marcas registradas de VIDEOROLA® en los Estados Unidos Mexicanos y otros países. Otras marcas,
                        gráficos y logos utilizados en relación con LA APLICACION pueden ser marcas comerciales de sus respectivos propietarios. Por lo que “EL USUARIO” no recibe derecho o licencia alguna con respecto a ninguna de las marcas comerciales
                        anteriormente mencionadas ni al uso de dichas marcas.
                    </p>
                    <p class="text-justify text-lightx">
                        Todos los derechos de autor contenidos en la “LA APLICACION” (incluyendo publicaciones, enlaces a otros recursos de Internet) son propiedad de VIDEOROLA® y/o sus licenciantes, quienes se reservan todos los derechos legales pertinentes. SE ENCUENTRA ESTRICTAMENTE
                        PROHIBIDO EL USO DE LA APLICACION DE MANERA DISTINTA A LA PERMITIDA CONFORME AL PRESENTE CONTRATO, SÍ DICHO USO INFRINGE DERECHOS DE PROPIEDAD INTELECTUAL DE TERCEROS SE IMPONDRÁN SANCIONES QUE ESTIPULA LA LEY, ASI COMO POSIBLES
                        INDEMNIZACIONES MONETARIAS POR DAÑOS POR VIOLACIÓN A DERECHOS DE AUTOR A FAVOR DE VIDEOROLA® Y O SUS EMPRESAS DEL GRUPO.
                    </p>
                    <p class="text-justify text-lightx">
                        LA “APLICACION” se encuentra protegido por derechos de autor y es secreto industrial, por lo que contienen información propiedad de VIDEOROLA®. “El USUARIO” no está autorizado para traducir, descompilar, desensamblar, o hacer ingeniería en reversa de
                        “LA APLICACION” o su documentación, en cualquier forma.
                    </p>
                    <hr/>
                    <strong class="text-left">
                        <h5 class="text-lightx">DIRECCIONES CON TERCEROS Y SUS SERVICIOS</h5>
                    </strong>
                    <p class="text-justify text-lightx">
                        VIDEOROLA® a través de “LA APLICACION” puede proveer, o un tercero puede proveer, enlaces a otros sitios web, aplicaciones, u otros recursos y servicios creados por terceras partes (“Servicios de Terceros”). Cuando usted adquiere servicios de un tercero,
                        esta interactuado con ese tercero, no con VIDEOROLA®, Si usted elige utilizar los servicios de un tercero y compartir información con él, entonces esos servicios del tercero pueden compartir su información de acuerdo con el aviso
                        de privacidad de ese tercero. En adición, el tercero proveyendo el servicio puede utilizar a su vez otra entidad para proveer porciones de la aplicación, como tecnología, desarrollo o servicios de pago. VIDEOROLA® no es responsable,
                        ni otorga ninguna garantía, expresa o tácita hacía los servicios del tercero olos proveedores del mismo (incluyendo, pero no limitando a, la certeza o completitud de la información proveída por los servicios del tercero), además
                        la inclusión del servicio de un tercero o un acceso no implica aprobación de los servicios o el contenido de este tercero.
                    </p>
                    <p class="text-justify text-lightx">
                        VIDEOROLA® no es responsable por el contenido o prácticas de cualquier sitio web que no sea la de “LA APLICACION”, incluso si la página web enlaza al sitio de “LA APLICACION” e incluso si esta, se encuentra operada por una empresa filial u otra compañía
                        conectada con el sitio de “LA APLICACION”. Al usar “LA APLICACION”, EL USUARIO reconoce y acepta que VIDEOROLA® no es responsable por cualquier contenido u otro material alojado para el servicio de cualquier sitio web que no sea
                        el de “LA APLICACION”. Cuando “El USUARIO” accede a los servicios de un tercero, lo hace bajo su propio riesgo, por lo que VIDEOROLA® recomienda no proveer ningún tipo de información identificable a cualquier tercero, a menos que
                        “El USUARIO” reconozca y se sienta seguro con la parte de la que se encuentra interesada.
                    </p>
                    <hr/>
                    <strong class="text-leftx">
                        <h5 class="text-light">MISCELÁNEOS</h5>
                    </strong>
                    <p class="text-justify text-lightx">
                        El presente Contrato representa el acuerdo íntegro entre EL USUARIO y VIDEOROLA® y regula su uso de “LA APLICACION”, y reemplaza todo otro acuerdo anterior que EL USUARIO haya celebrado con VIDEOROLA®. EL USUARIO también puede estar sujeto a términos
                        y condiciones adicionales que pueden aplicarse cuando utilice servicios de afiliados, contenido de terceros o LA APLICACIÓN de terceros. En el supuesto de que alguna de las disposiciones del presente Contrato resultara inválida
                        o inexigible, dicha disposición se interpretará de tal modo que se ajuste con las leyes pertinentes para reflejar, en la medida de lo posible, la intención original de las partes, y ello no afectará la validez y exigibilidad de
                        resto de las disposiciones de este Contrato. El hecho de que VIDEOROLA® no ejerza algún derecho o haga cumplir una disposición conforme al presente Contrato no constituirá una renuncia a dicha disposición o a cualquier otra, y
                        no será responsable por el incumplimiento de obligaciones por causas ajenas o fuera de su control.
                    </p>
                    <p class="text-justify text-lightx">
                        VIDEOROLA® opera “LA APLICACION” desde sus oficinas de ubicadas en Guadalajara, Jalisco. EL USUARIO se compromete a cumplir con las leyes locales, estatales y federales, como también con otras normas, ordenanzas y reglamentos pertinentes en lo que respecta
                        al uso de “LA APLICACION”. Toda transacción sobre “LA APLICACION” se encuentra regulada por las leyes de Guadalajara, Jalisco sin que exista conflicto de leyes, por lo que “EL USUARIO” acepta expresamente que los tribunales competentes
                        para la resolución de todo reclamo o controversia con VIDEOROLA® o de controversias relacionadas con el uso que usted haga de “LA APLICACION” serán los tribunales en el Estado de Guadalajara, Jalisco. Ningún empleado o agente de
                        VIDEOROLA® está autorizado para modificar el presente Contrato.
                    </p>
                    <p class="text-justify text-lightx">
                        El presente acuerdo inicia su vigencia en la fecha de la aceptación por “”EL USUARIO”
                    </p>
                </div>
            </div>
        </div>
      `,
          confirmButtonText: "Siguiente &rarr;",
          confirmButtonClass: "btn-vr",
          confirmButtonColor: "rgba(20,20,20,0.9)",
          cancelButtonColor: "rgba(30,30,30,0.9)",
        },
        {
          html: `
        <div class="container-fluid">
        <div class="row" style=" color: rgb(169, 169, 169);">
            <div class="col-md-12" style="height:500px;overflow:scroll;">
            <h4 class="text-left">Aviso de Privacidad!</h4>
            <hr>
            <p class="text-justify text-ligth">
            Entretenimiento Satelital S.A. de C.V. (en lo sucesivo VideoRola), con domicilio en Av. Lázaro Cárdenas No. 1694-316, colonia del Fresno, C.P. 44900, Guadalajara, Jalisco, es responsable de recabar sus datos personales, del uso que se les da a los mismos y de su protección.
            </p>
            <hr/>
            <p class="text-justify">
            VideoRola como prestadora de servicios relacionados con una plataforma de reproducción de contenido musical por Internet (en lo sucesivo se le denominará como el “Servicio”), recaba para la prestación de servicios datos personales, ya sea de identificación y/o financieros proporcionados por usted de manera directa o por cualquier otro medio de contacto.
            </p>
            <p class="text-left">
            Sus datos personales serán utilizados para las siguientes finalidades:
            </p>
            <ul class="text-left">
              <li>Proveer de los servicios requeridos por usted.</li>
              <li>Informar sobre los cambios o nuevos productos o servicios que estén relacionados el “Servicio”.</li>
              <li>Dar cumplimiento a obligaciones contraídas con nuestros usuarios.</li>
              <li>Evaluar la calidad del servicio.</li>
              <li>Realizar estudios internos sobre hábitos de consumo.</li>
              <li>Promover las ofertas y/o promociones que ofrecemos a nuestros usuarios.</li>
              <li>Para difundir publicidad sobre los servicios que oferta VideoRola y que son de su interés.</li>
              <li>Para enviar notas informativas a través de aplicaciones a nuestros suscriptores.</li>
            </ul>
            <hr>
            <p class="text-justify">
            Para las finalidades señaladas en el presente aviso de privacidad, podemos recabar sus datos personales de distintas formas; (i) cuando usted nos los proporcione de manera directa (ii) cuando visita nuestro sitio de internet o utiliza nuestro “Servicio”, y (iii) cuando obtenemos información a través de otras fuentes que están permitidas por la ley
            </p>
            <p class="text-justify">
            Datos personales que recabamos de forma directa cuando usted mismo nos los proporciona por diversos medios, información con objeto de que le prestemos el Servicio. Los datos que obtenemos por este medio pueden ser, entre otros:
            </p>
            <ul class="text-left">
              <li>Nombre</li>
              <li>Domicilio</li>
              <li>Número Telefónico.</li>
              <li>Correo Electrónico.</li>
            </ul>
            <p class="text-justify">
            Le informamos que, para cumplir con las finalidades previstas en el presente aviso de privacidad, serán recabados y tratados como datos personales sensibles, aquéllos que refieren a obtener los datos financieros como los son datos de su tarjeta de crédito y/o débito, para realizar pago o contratar el Servicio, en cualquier de los casos siempre se le solicitará de su autorización para el tratamiento de sus datos personales sensibles.
            </p>
            <p class="text-justify">
            Nos comprometemos a que los mismos serán tratados bajo las más estrictas medidas de seguridad que garanticen su confidencialidad.
            </p>
            <p class="text-justify">
            Usted tiene derecho de acceder a sus datos personales que poseemos y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o incompletos; cancelarlos cuando considere que no se requieren para alguna de las finalidades señalados en el presente aviso de privacidad, estén siendo utilizados para finalidades no consentidas o haya finalizado la relación contractual o de servicio, o bien, oponerse al tratamiento de los mismos para fines específicos.
            </p>

            A estos derechos se les denominará en lo sucesivo derechos ARCO.
            </p>
            <p class="text-justify">
            Los mecanismos que se han implementado para el ejercicio de dichos derechos son a través de los siguientes medios:
            </p>
            <ol class="text-left">
              <li>Presentación de la solicitud respectiva en las oficinas de VideoRola en el domicilio descrito en el proemio del a presente Aviso de Privacidad.</li>
              <li>Envió de la solicitud respectiva al correo electrónico dispuesto para ello en la plataforma de el “Servicio ”.</li>
            </ol>
            <p class="text-justify">
            La solicitud deberá contener la siguiente información:
            </p>
            <ul class="text-left">
              <li>
              Nombre del usuario.
              </li>
              <li>
              Domicilio.
              </li>
              <li>
              Teléfono.
              </li>
              <li>
              Correo electrónico.
              </li>
              <li>
              Fecha en que ingresa solicitud.
              </li>
              <li>
              Derecho que quiere ejercer de acuerdo a los derechos ARCO. (ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOCISIÓN.)
              </li>
              <li>
              Firma
              </li>
            </ul>
            <p class="text-justify">
            A la solicitud, se debe adjuntar la identificación del titular de la información para poder acreditar la titularidad.
            </p>
            <p class="text-justify">
            Una vez ingresada la solicitud, el departamento responsable atenderá la petición y le dará el trámite correspondiente dentro de los términos y lineamientos que estable le Ley.
            </p>
            <p class="text-justify">
            Nos comprometemos a no transferir su información personal a terceros sin su consentimiento, salvo las excepciones previstas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares, así como a realizar esta transferencia en los términos que fija está ley.
            </p>
            <p class="text-justify">
            Si usted no manifiesta su oposición para que sus datos personales sean transferidos, se entenderá que ha otorgado su consentimiento para ello.
            </p>
            <p class="text-justify">
            En caso que usted decidiera no otorgar su consentimiento para realizar la transferencia de sus datos personales, puede presentar en las oficinas de VideoRola o vía correo electrónico al contacto dispuesto para ello en la plataforma, en el cual se le solicitará la siguiente información:
            </p>
            <ul class="text-left">
            <li>Nombre del usuario.</li>
            <li>Correo Electrónico.</li>
            <li>Firma</li>
            <li>Acompañado de una copia de su identificación oficial para validar la titularidad de los datos.</li>
            </ul>
            <p class="text-justify">
               En el caso de usted haya otorgado su consentimiento para realizar la transferencia de sus datos personales, en cualquier tiempo puede revocar dicho consentimiento mediante la NEGATIVA PARA LA TRANSFERENCIA DE DATOS PERSONALES, bajo los procesos que se describen en los párrafos que anteceden.
            </p>
            <p class="text-justify">
            Cualquier modificación al presente aviso le será notificada a través de los mensajes en la plataforma.
            </p>

            </div>
        </div>
        </div>
      `,
          focusConfirm: false,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar!',
          confirmButtonAriaLabel: "Acepto!",
          cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
          cancelButtonAriaLabel: "Thumbs down",
          confirmButtonClass: "btn-vr",
          confirmButtonColor: "rgba(20,20,20,0.9)",
          cancelButtonColor: "rgba(30,30,30,0.9)",
        },
      ])
      .then((result) => {
        if (result.value) {
          this.statusConditions = true;
          this.onSubmitRegister();
        } else {
          this.statusConditions = false;
        }
      });
  }

  public showSignInView() {
    this.changeView.emit(AUTH_VIEWS.SIGN_IN);
  }

  public async signInApple() {
    try {
      AppleID.auth.init({
        clientId: "VRSignIn",
        scope: "name email",
        redirectURI: API.dev.apple_callback,
        state: "vr-init",
        nonce: "vr",
        usePopup: true,
      });
      const data = await AppleID.auth.signIn();
      const { email, sub } = this.authenticate.parseJwt(
        data.authorization.id_token
      );
      this.completeSignUp(
        { sub: sub, name: "", email: email, image: "", idToken: "" },
        "apple"
      );
    } catch (error) {
      console.log("Error in signin", error);
    }
  }

  private completeSignUp(userData: any, provider?: string) {
    const token = userData.idToken;
    const fullName = this.authenticate.extractName(userData.name);
    let emailX = userData.email;
    const photo = userData.image;
    if (provider && provider == "apple") {
      //TODO: To extract data the different way
      emailX = userData.sub;
    }
    // Get information
    const t = this.authenticate.getUserInformation(emailX,userData.email).subscribe(
      (data) => {
        let userParam =
          provider && provider == "apple" ? data.user.user_id : data.user.email;
        let userTypeParam =
          provider && provider == "apple" ? provider : "VR-EMAIL";
        const ts = this.authenticate
          .checkSession(userParam, userTypeParam)
          .subscribe(
            (res) => {
              if (res.data.result === true) {
                // if there are
                dataLayer.push({'event':'evSignup'});
                this.authenticate.setCurrentProviderAuth("SL-A");

                data.user.picture = photo;
                data.name = fullName.name;
                data.surnames = fullName.lastName;
                this.authenticate.updateUser(data.user);
                this.authenticate.setCurrentUserInformation(data.user);
                this.global.redirect();
                this.authenticate.setCurrentUserInformation(data.user);

                // Register session
                const temp = this.authenticate
                  .getSession(
                    data.user.user_id,
                    "Web Browser",
                    "Unknow",
                    "Laptop-PC"
                  )
                  .subscribe(
                    (success) => {
                      console.log(success.data.session);
                      this.authenticate.setCurrentSession({
                        id: success.data.session.session_id,
                        user: success.data.session.user,
                        os: success.data.session.os,
                        device: success.data.session.device,
                        type_device: success.data.session.type_device,
                        created_at: success.data.session.creation_date,
                      });
                      temp.unsubscribe();
                    },
                    (error) => {
                      temp.unsubscribe();
                    }
                  );

                this.authenticate.registerInOneSignal(data.user.email);
              } else if (res.data.result === false) {
                let inputOptions = {};
                for (var i = 0; i < res.data.sessions.length; i++) {
                  inputOptions[
                    res.data.sessions[i].session_id +
                      "-" +
                      res.data.sessions[i].user
                  ] =
                    "Tipo:" +
                    res.data.sessions[i].device_type +
                    " Fecha:" +
                    res.data.sessions[i].creation_date.split(" ")[0] +
                    " Dispositivo:" +
                    res.data.sessions[i].device;
                }
                this.showMessageSessions("warning", inputOptions, false);
              }
              ts.unsubscribe();
            },
            (err) => {
              ts.unsubscribe();
              this.showMessage("Ups!", "", "error");
            }
          );
        t.unsubscribe();
      },
      (error) => {
        t.unsubscribe();
      }
    );
  }
  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider: string;
    if (socialPlatform === "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAutg
      .signIn(socialPlatformProvider)
      .then((userData) => {
        this.completeSignUp(userData);
      })
      .catch((e) => {
        this.showMessage(
          "Ups!",
          "No fue posible comunicarse con el proveedor de identidad, intentalo más tarde",
          "error"
        );
      });
  }

  showMessage(title: string, content: string, type: SweetAlertType) {
    Swal.fire({
      type: type,
      title: title,
      text: content,
      background: "rgba(0,0,0,0.9)",
      confirmButtonColor: "rgba(22,22,22,0.9)",
      timer: 3500,
    });
  }

  showMessageSessions(
    type: SweetAlertType,
    inputOptions: {},
    redirect = false
  ) {
    Swal.fire({
      type: type,
      title:
        "Ups! Has llegado al límite de dispositivos autorizados. Cierra sesión en alguno e inténtalo de nuevo en este.",
      input: "select",
      inputOptions: inputOptions,
      inputPlaceholder: "Dispositivos Activos",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          let keys = value.split("-");
          let session_id = keys[0];
          let user_id = keys[1];
          this.authenticate.closeSelectedSession(user_id, session_id).subscribe(
            (data) => {
              console.log(data);
              if (redirect) {
                this.showMessage(
                  "Éxito",
                  "Iniciando sesión en este dispositivo.",
                  "success"
                );
                // this.onSubmitLogin()
              } else {
                this.showMessage(
                  "Éxito",
                  "Éxito ahora puedes iniciar sesión en este dispositivo.",
                  "success"
                );
              }
            },
            (error) => {
              resolve(
                "Ocurrió un problema al cerrar sesión de manera remota, favor de intentar de nuevo."
              );
            }
          );
        });
      },
      background: "rgba(0,0,0,0.9)",
      confirmButtonColor: "rgba(22,22,22,0.9)",
      confirmButtonText: "Seleccionar",
    });
  }
}
