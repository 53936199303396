import { AuthenticationService } from "./authentication.service";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Injectable } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import API from "./../../configs/ApisConfig";
import Swal from "sweetalert2";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { ViewerComponent } from "../components/viewer/viewer.component";
@Injectable({
  providedIn: "root",
})
export class HomeService {
  public elements: any[];
  public isLoading = true;
  private subscription: Subscription;
  public termsAndShowing: boolean = false;

  constructor(
    private userS: AuthenticationService,
    private http: HttpClient,
    private modal: NgbModal
  ) {}

  public getAccceptance() {
    if (
      !this.userS.getCurrentUserInformation() ||
      this.termsAndShowing === true
    ) {
      return;
    }

    const r = this.userS
      .checkTerms(this.userS.getCurrentUserInformation().user_id)
      .subscribe((e) => {
        this.verifyAcceptance(e.terms_and_policy, "policy");
        r.unsubscribe();
      });
  }

  public verifyAcceptance(data: any, type: string) {
    console.log("verifyAcceptance...");
    if (data[`${type}_accepted`] === false) {
      this.termsAndShowing = true;
      const ref = this.modal.open(ViewerComponent, {
        size: "lg",
        backdrop: "static",
        keyboard: false,
      });
      ref.componentInstance.title =
        type !== "policy" ? "Términos y Condiciones" : "Política de privacidad";
      ref.componentInstance.content = data[`${type}_text`].replaceAll(
        "\n",
        "<br/>"
      );
      ref.result.then((v) => {
        if (v === true) {
          data[`${type}_accepted`] = true;
          this.userS
            .updateTermsAcceptance(
              this.userS.getCurrentUserInformation().user_id,
              data[`${type}_id`],
              type
            )
            .subscribe((e) => {
              console.log(e);
              this.verifyAcceptance(data, "terms");
            });
        }
      });
    } else if (type === "policy") {
      if (data[`terms_accepted`] === false) {
        this.verifyAcceptance(data, "terms");
      }
    }
  }

  public refreshElements() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.getHome();
  }

  public getHome(): void {
    let id = "";
    if (this.userS.getCurrentUserInformation()) {
      id = this.userS.getCurrentUserInformation().user_id;
    }
    this.subscription = this.getHomeScreen(id).subscribe(
      (data) => {
        // console.log(data)
        this.elements = data.homescreen;
        this.isLoading = false;
        this.subscription.unsubscribe();
      },
      (error) => {
        Swal.fire({
          title:
            "<strong style=\"color:#fff; font-family:'vr-thin';\">¡Error al cargar la pagina!</strong>",
          type: "error",
          background: "rgba(30, 30, 30, 0.964)",
          html: `<span style="color:rgba(255,255,255,.8)"></span> `,
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText:
            '<i class="fa fa-arrow-circle-up"></i> Reintentar!',
          confirmButtonAriaLabel: "Thumbs up, great!",
          confirmButtonColor: "rgba(0, 0, 0, 0.249)",
        }).then((res) => {
          this.subscription.unsubscribe();
          this.getHome();
        });
      }
    );
  }

  public extractData(res: Response): any {
    return res;
  }

  getHomeScreen(id: any): Observable<any> {
    return this.http
      .get(API.dev.url + API.dev.TemplatePaths.HOME_SCREEN_NEW + "/" + id)
      .pipe(map(this.extractData));
  }

  addClick(id: string): Observable<any> {
    return this.http
      .get(API.dev.url + API.dev.TemplatePaths.COUNT_AD + id)
      .pipe(map(this.extractData));
  }
}
