import { Router, NavigationEnd } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import API from "./../configs/ApisConfig";
import { map, filter } from "rxjs/operators";

@Injectable()
export class GlobalsService {
  /* History */
  public routeHistory: string[] = [];
  public isBlockBySession: boolean;

  // Menus //
  /* 0 = Main, 1 = PlayList */
  public currentMenu = 0;

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient, private route: Router) {
    this.route.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.routeHistory.push(urlAfterRedirects);
      });
  }

  public getPreviousUrl(): string {
    return this.routeHistory[this.routeHistory.length - 2] || "/";
  }

  public extractData(res: Response): any {
    const body = res;
    return body || {};
  }

  /**
   * v1.0
   */
  getAdvertising(): Observable<any> {
    return this.http
      .get(API.dev.url + API.dev.TemplatePaths.MAIN_ADVERTISING)
      .pipe(map(this.extractData));
  }

  /**
   *
   * @param atr Last search
   */
  public setLastSearch(atr: string) {
    localStorage.setItem("lastSearch", atr);
  }

  /**
   * v1.0
   */
  public getLastSeacrh() {
    const val = localStorage.getItem("lastSearch");
    return val === null ? "" : val;
  }

  /**
   * v1.0
   */
  sendSupportMessage(
    mail: string,
    subject_: string,
    conetent: string
  ): Observable<any> {
    return this.http.post(
      API.dev.url + API.dev.TemplatePaths.SUPPORT,
      {
        queryStringParameters: {
          reply_to: mail,
          subject: subject_,
          content: conetent,
        },
      },
      this.httpOptions
    );
  }

  // utils.js

  /**
   * v1
   */
  getPublicIp(): Observable<any> {
    return this.http.get(API.prod.PUBLIC_IP).pipe(map(this.extractData));
  }

  isTrackAllowed(ip: string): Observable<any> {
    return this.http.get(API.prod.GEOBLOCKING + ip).pipe(map(this.extractData));
  }

  getCurrentPosition(options = {}): any {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }

  getCurrentPositionByIp() {
    return this.http.get(API.prod.IP_LOCATION).pipe(map(this.extractData));
  }

  async getHttpToken(url: string) {
    return await this.http
      .post(API.dev.url + API.prod.ACCESS_TOKEN, {
        playback_url: url,
      })
      .toPromise();
  }

  setupAdsToken(data: any, currentDate: number) {
    localStorage.setItem(
      "ads-user-id-goole",
      JSON.stringify({
        tokenId: currentDate,
        adId: btoa(data.stream_key),
        clientAccessToken: btoa(data.stream_policy),
      })
    );
  }

  buidlTokenFrom(data: any, parse = false) {
    if (!parse) return data;
    return { stream_key: data.x, stream_policy: data.y };
  }

  async setupInitialTokens(url: string, date: number) {
    const data: any = await this.getHttpToken(url);
    this.setupAdsToken(data, date);
    return this.buidlTokenFrom(data);
  }

  async getAccessToken(streaming: string) {
    const currentDate = new Date();
    const checkedPerDay = false;
    let localStorageEnabled = false;

    if (checkedPerDay && localStorage !== null) {
      const hasData: any = JSON.parse(
        localStorage.getItem("ads-user-id-goole")
      );
      localStorageEnabled = true;
      if (
        hasData &&
        hasData.tokenId &&
        hasData.adId &&
        hasData.clientAccessToken
      ) {
        const daysDiff = Math.ceil(
          Math.abs(currentDate.getTime() - hasData.tokenId) /
            (1000 * 60 * 60 * 24)
        );
        if (daysDiff > 1) {
          return this.setupInitialTokens(streaming, currentDate.getTime());
        } else {
          return this.buidlTokenFrom(
            {
              x: atob(hasData.adId),
              y: atob(hasData.clientAccessToken),
            },
            true
          );
        }
      } else {
        return this.setupInitialTokens(streaming, currentDate.getTime());
      }
    }
    if (!localStorageEnabled)
      return this.buidlTokenFrom(await this.getHttpToken(streaming));
  }

  getCurrentLocation(): any {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const locationData = {
          lt: pos.coords.latitude,
          ln: pos.coords.longitude,
        };
        localStorage.setItem("c_l", JSON.stringify(locationData));
        return locationData;
      });
    } else {
      return {};
    }
  }

  getGeoName(lon, lat): Observable<any> {
    return this.http
      .get(API.prod.IP_LOCATION.replace("xan", lat).replace("lon", lon))
      .pipe(map(this.extractData));
  }

  /**
   *  v1.0
   */
  redirect() {
    setTimeout(() => {
      if (this.isBlockBySession) {
        console.log(this.getPreviousUrl());
        this.route.navigateByUrl(this.getPreviousUrl());
        this.isBlockBySession = false;
        this.routeHistory.length = 0;
      } else {
        this.route.navigate(["/"]);
      }
    }, 1000);
  }

  fastRedirect() {
    if (this.isBlockBySession) {
      console.log(this.getPreviousUrl());
      this.route.navigateByUrl(this.getPreviousUrl());
      this.isBlockBySession = false;
      this.routeHistory.length = 0;
    } else {
      this.route.navigate(["/"]);
    }
  }
}
