// import { PlayListControlService } from './../../../services/play-list-control.service';
import { IGridItem } from "./../../../models/IGridItem";
import { IPlayList } from "./../../../models/IPlayList";
import { ITrack } from "./../../../models/ITrack";

import { Router } from "@angular/router";
import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { ContextMenuComponent, ContextMenuService } from "ngx-contextmenu";

@Component({
  selector: "app-cover-page-media",
  templateUrl: "./cover-page-media.component.html",
  styleUrls: ["./cover-page-media.component.css"],
})
export class CoverPageMediaComponent implements OnInit {
  @Input() index: String;
  @Input() type: any;
  @Input() track: ITrack;
  @Input() playList: IPlayList;
  @Input() config: {
    playIcon: Boolean;
    assetDate: Boolean;
    btnDetail: Boolean;
  } = { playIcon: true, assetDate: false, btnDetail: true };
  @Input() gridItem: IGridItem;
  @Input() origin: string;

  @ViewChild(ContextMenuComponent) basicMenu: ContextMenuComponent;
  @Input() contextMenu: ContextMenuComponent;

  public idSelectedPlayList: string;
  constructor(
    private router: Router,
    public contextMenuService: ContextMenuService // public pl: PlayListControlService
  ) {}

  ngOnInit() {
    //console.log(this.type + ' -- ' + this.origin);
  }

  toDetail(type: string) {
    console.log(type);
    switch (type) {
      case "last":
        switch (this.origin) {
          case "own":
            this.router.navigate(["watch/play-lists", "my-play-lists"]);
            break;
          case "concerts":
            this.router.navigate(["watch/play-lists", "concerts"]);
            break;
          case "programmes":
            this.router.navigate(["watch/play-lists", "programmes"]);
            break;
          case "publiccontent":
            this.router.navigate(["watch/play-lists", "publiccontent"]);
            break;
          case "concerts":
            this.router.navigate(["watch/play-lists", "recommended-lists"]);
            break;
          case "search":
            // NOP NO ACTION IN THIS CASE
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  public onContextMenu($event: KeyboardEvent, item: any): void {
    this.contextMenuService.show.next({
      anchorElement: $event.target,
      contextMenu: this.basicMenu,
      event: <any>$event,
      item: item,
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  public addContent(id: string): void {
    this.router.navigate(["watch/play-lists/detail", id]);
  }

  public deletePlayList(id: string) {
    // this.pl.deletePlayList()
  }
}
