import { Subscription } from "rxjs";
import { Component, OnDestroy } from "@angular/core";
import { Router, RouterEvent } from "@angular/router";
import { NotificationAction } from "./models/NotificationAction";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnDestroy {
  allowOnMobil = false;
  private sub: Subscription;
  constructor(private router: Router) {
    this.notificationHandler();
    this.sub = this.router.events.subscribe((event: RouterEvent) => {
      if (
        event.url &&
        (event.url.startsWith("/promotions") ||
          event.url.startsWith("/terminos-de-uso") ||
          event.url.startsWith("/politicas-de-privacidad"))
      ) {
        this.allowOnMobil = true;
      } else {
        if (event.url !== undefined) {
          this.allowOnMobil = false;
        }
      }
    });
  }

  toggle() {
    this.allowOnMobil = !this.allowOnMobil;
  }

  public notificationHandler(): void {
    try {
      const OneSignal = window["OneSignal"] || [];
      OneSignal.push([
        "addListenerForNotificationOpened",
        (data) => {
          this.handelNotificationAction(data.data);
        },
      ]);
    } catch (error) {
      console.log("HANDLE NOTIFICATION");
      console.error(error);
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  private handelNotificationAction(data: NotificationAction) {
    try {
      switch (data.type) {
        case "live":
          this.router.navigateByUrl("/live");
          break;
        case "playlist":
          this.router.navigate(["/watch", "playlist-vr"], {
            queryParams: { l: data.element_id, t: data.element_id },
          });
          break;
        case "asset":
          this.router.navigate(["/watch", "last-moment"], {
            queryParams: { l: "grid", t: data.element_id },
          });
          break;
        default:
          this.router.navigateByUrl("/notifications");
          break;
      }
    } catch (error) {}
  }
}
