import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import API from "../configs/ApisConfig";

@Injectable()
export class PaymentService {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  promotions(userId: string): any {
    return this.http.get(
      `${API.dev.url + API.dev.TemplatePaths.VR_PACKAGES + userId}`
    );
  }

  cardOrder(
    userId: string,
    promotionId: string,
    token: string,
    userName: string,
    currency: string
  ): Observable<any> {
    return this.http.post(
      `${
        API.dev.url +
        API.dev.TemplatePaths.CARD_CONEKTA +
        userId +
        "/" +
        promotionId +
        "/" +
        token +
        "/" +
        userName +
        "/" +
        currency
      }`,
      {}
    );
  }

  applyAndConfirm(data: {}): Observable<any> {
    return this.http.post(
      `${API.dev.url + API.dev.TemplatePaths.CONFIRM_PURCHASE}`,
      data
    );
  }

  oxOrder(userId: string, promotionId: string): Observable<any> {
    return this.http.post(
      `${
        API.dev.url + API.dev.TemplatePaths.OXXO_CONEKTA
      }${userId}/${promotionId}`,
      {}
    );
  }

  oxxoOrder(userId: string, promotionId: string): Observable<any> {
    return this.http.post(
      `${
        API.dev.url +
        API.dev.TemplatePaths.OXXO_CONEKTA +
        userId +
        "/" +
        promotionId
      }`,
      {},
      this.httpOptions
    );
  }

  codeForUser(code: string, userId: string): Observable<any> {
    return this.http.post(
      `${API.dev.url + API.dev.TemplatePaths.USER_CODE}${code}/${userId}`,
      {}
    );
  }
}
