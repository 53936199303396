import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


import Amplify from 'aws-amplify';
import amplify from './aws-exports';

import 'hammerjs';
Amplify.configure(amplify);


const oauth = {
  domain: 'videorola.com',
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: 'https://www.videorola.com',
  redirectSignOut: 'https://www.videorola.com',
  responseType: 'token',
  options: {
    AdvancedSecurityDataCollectionFlag: false
  }
};

Amplify.configure({ oauth });


if (environment.production) {
  enableProdMode();
  console.log = ()=>{};
  window.console.log = () => {};
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
