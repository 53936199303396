import { AuthenticationService } from './../core/services/authentication.service';
import { Router, ActivatedRoute, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthenticationService) {

  }

  // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   if (localStorage.getItem('currentUser')) {
  //     // if logged return true

  //     return true;
  //   }

  //   this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  //   return false;
  // }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log('Guard :: ', next.url);
    if (this.auth.getCurrentUserInformation()) {
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }

}

