const DATA = {
  streams_categories: {
    video: {
      id: 'ed4096845e8b47ff8731bfbc57178b96'
    },
    concerts: {
      id: '6f1912d5c8d1413cae22868fe214b29a'
    },
    programmes: {
      id: 'd2fb9e1a340c4abfae9cb8da3e2040b0'
    },
    publiccontent: {
      id: '2ba05dd6c4a44b8bbbd8321cd6a901a0'
    }
  }
};

export default DATA;

