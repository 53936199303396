import { Subscription } from 'rxjs';
import { IUser } from './../../../../../models/IUser';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../../services/authentication.service';

@Component({
  selector: 'app-bio',
  templateUrl: './bio.component.html',
  styleUrls: ['./bio.component.css']
})
export class BioComponent implements OnInit, OnDestroy {

  currentUser: IUser;
  private userSubscription: Subscription;
  private photoName = '';
  image = 'https://www.jamf.com/jamf-nation/img/default-avatars/generic-user-purple.png';
  age = '';

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {
    this.userSubscription = this.authService.currentUserAuth.subscribe(x => this.currentUser = x);

  }

  ngOnInit() {
    this.currentUser = this.authService.getCurrentUserInformation();
    this.currentUser.gender = this.currentUser.gender === 'NAN' ? '' : this.currentUser.gender;
    if (this.currentUser.picture === '') {
      this.currentUser.picture = this.image;
    }
    if (this.currentUser.birthdate && this.currentUser.birthdate.length > 0) {
      this.age = new Date().getFullYear() - new Date(this.currentUser.birthdate).getFullYear() + ' años';
    } else {
      this.age = new Date().getFullYear() + ' ';
    }


  }

  public toEditPorfile() {
    this.router.navigateByUrl('/editProfile');
  }


  public updateProfilePhoto(event) {
    const files = event.srcElement.files;
    if (!files) {
      return;
    }

    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(files[0]);
      const aux = files[0].name.split('.');
      const ex = aux[aux.length - 1];
      this.photoName = new Date().getTime() + 'me.' + ex;
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    const base64 = btoa(binaryString);
    //console.log(this.photoName);

    this.authService.updatePhoto(this.currentUser.user_id, this.photoName, base64).toPromise()
      .then(data => {
        this.currentUser.picture = data.image_url;
        this.authService.setCurrentUserInformation(this.currentUser);
        const toast = Swal.mixin({
          toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, background: 'rgba(0,0,0,.5)'
        });
        toast.fire({
          type: 'success',
          title: 'Foto de perfil actualizada!'
        });
      }).catch(err => {

      });

  }


  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

}
