import { Subscription } from "rxjs";
import { IUser } from "./../../../../models/IUser";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../services/authentication.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import Swal from "sweetalert2";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  stateInputs = "false";
  profileForm: FormGroup;
  changePasswordForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  submittedPassword = false;
  private photoName = "";
  image =
    "https://www.jamf.com/jamf-nation/img/default-avatars/generic-user-purple.png";
  currentUser: IUser;
  currentProvider: string;
  private profileSubscription: Subscription;
  Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    background: "rgba(0,0,0,.5)",
  });

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.profileSubscription = this.authService.currentUserAuth.subscribe(
      (x) => (this.currentUser = x)
    );
  }

  toBio() {
    this.router.navigateByUrl("/bio");
  }

  ngOnInit() {
    this.profileForm = this.formBuilder.group({
      name: ["", Validators.required],
      email: ["", Validators.required],
      address: ["", Validators.required],
      phone_number: ["", Validators.required],
      gender: ["", Validators.required],
      nickname: ["", Validators.required],
      familyName: [""],
      birthdate: [],
    });

    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ["", [Validators.required, Validators.minLength(8)]],
      newPassword: ["", [Validators.required, Validators.minLength(8)]],
    });

    this.currentUser = this.authService.getCurrentUserInformation();
    this.currentProvider = this.authService.getCurrentProviderAuth();

    if (this.currentUser.picture === "") {
      this.currentUser.picture = this.image;
    }
  }

  get form() {
    return this.profileForm.controls;
  }

  saveInfo(): void {
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    } else {
      const tempSub = this.authService.updateUser(this.currentUser).subscribe(
        (data) => {
          console.log(data);
          this.authService.setCurrentUserInformation(data.data.user);
          this.authService.currentAuthUserSubject.next(data.data.user);
          this.showToastMessage("success", "Perfil actualizado!");
          this.router.navigateByUrl("/bio");
          tempSub.unsubscribe();
        },
        (error) => {
          tempSub.unsubscribe();
          this.showToastMessage(
            "error",
            "Ha ocurrido un error! Intentalo más tarde!"
          );
        }
      );
    }
  }

  get changePForm() {
    return this.changePasswordForm.controls;
  }

  changePassword() {
    this.submittedPassword = true;
    if (this.changePasswordForm.invalid) {
      let msg =
        "Parece que alguno de tus datos no es correcto. Revisa e intenta de nuevo.";
      if (this.changePasswordForm.get("newPassword").hasError("minlength")) {
        msg = "La contraseña debe tener un minimo 8 caracteres!";
      }
      Swal.fire({
        type: "error",
        title: "Ups!",
        text: msg,
        customClass: "back-swal",
        confirmButtonColor: "#2e2e2eea",
      });
    } else {
      this.authService
        .changePassword(
          this.changePForm.oldPassword.value,
          this.changePForm.newPassword.value
        )
        .then((r) => {
          this.showToastMessage("success", "Contraseña actualizada!");
          this.authService.logout();
          this.router.navigate(["login"]);
        })
        .catch((e) => {
          this.showToastMessage(
            "error",
            "Ha ocurrido un error! Intentalo más tarde!"
          );
        });
    }
  }

  public updateProfilePhoto(event) {
    const files = event.srcElement.files;
    const aux = files[0].name.split(".");
    const ex = aux[aux.length - 1];

    if (!files) {
      return;
    }

    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(files[0]);
      this.photoName = new Date().getTime() + "me." + ex;
    }
  }

  handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    const base64 = btoa(binaryString);
    this.authService
      .updatePhoto(this.currentUser.user_id, this.photoName, base64)
      .toPromise()
      .then((data) => {
        this.currentUser.picture = data.image_url;
        this.authService.setCurrentUserInformation(this.currentUser);
        this.showToastMessage("success", "Foto de perfil actualizada!");
      })
      .catch((err) => {
        this.showToastMessage(
          "error",
          "Ha ocurrido un error! Intentalo más tarde!"
        );
      });
  }

  showToastMessage(type: any, content: string) {
    this.Toast.fire({
      type: type,
      title: content,
    });
  }

  async confirmAccountDeletion() {
    if (confirm("¿Estás seguro de que quieres eliminar tu cuenta?")) {
      await this.authService.deleteAccount(
        this.currentUser.user_id,
        this.currentUser.email
      );
      this.showToastMessage("success", "Cuenta eliminada!");
      this.authService.deleteInfo();
      this.router.navigateByUrl("/");
    }
  }

  ngOnDestroy() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }
}
