import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../../../services/globals.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  constructor(private gblService: GlobalsService, private formBuilder: FormBuilder) { }

  formSupport: FormGroup;
  submitted = false;

  ngOnInit() {
    this.formSupport = this.formBuilder.group({
      subject: ['', Validators.required],
      content: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
  }


  get form() { return this.formSupport.controls; }

  /**
   * sendMessage
   */
  public sendMessage() {

    this.submitted = true;
    if (this.formSupport.invalid) {
      Swal.fire({
        type: 'error',
        title: 'Ups!',
        text: 'Parece que alguno de tus datos no es correcto. Revisa e intenta de nuevo.',
        confirmButtonColor: 'rgba(22,22,22,0.9)',
        customClass: 'error-swal'
      });
    } else {
      if (this.form.subject.value === -1) {
        Swal.fire({
          type: 'error',
          title: 'Ups!',
          text: 'Seleccione un tema!',
          confirmButtonColor: 'rgba(22,22,22,0.9)',
          customClass: 'error-swal'
        });
      } else {
        const subs = this.gblService.sendSupportMessage(
          this.form.email.value,
          this.form.subject.value,
          this.form.content.value
        ).subscribe(data => {
          Swal.fire({
            type: 'success',
            text: 'Tu solicitud ha sido recibida y será atendida a la brevedad!',
            background: 'rgba(30,30,30,0.9)',
            confirmButtonColor: 'rgba(22,22,22,0.9)',
            confirmButtonText: 'ACEPTAR'
          });
          this.formSupport.reset();
          this.submitted = false;
          subs.unsubscribe();
        }, err => {
          subs.unsubscribe();
        });
      }
    }
  }

}
