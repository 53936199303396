import { ProfileComponent } from './components/user/profile/profile.component';
import { AuthFormComponent } from './components/authentication/auth-form/auth-form.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './components/home/home.component';
import { BioComponent } from './components/user/profile/bio/bio.component';
import { PrivacyComponent } from './components/user/privacy/privacy.component';
import { TermsComponent } from './components/user/terms/terms.component';
import { SupportComponent } from './components/support/support.component';
import { AuthGuard } from '../guards/auth.guard';
import { PaymentFlowComponent } from '../shared/components/payment-flow/payment-flow.component';



const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: AuthFormComponent
  },
  {
    path: 'bio',
    component: BioComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'editProfile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'main',
    component: HomeComponent
  }, {
    path: 'politicas-de-privacidad',
    component: PrivacyComponent
  },
  {
    path: 'terminos-de-uso',
    component: TermsComponent
  },
  {
    path: 'support',
    component: SupportComponent
  },
  {
    path: 'search',
    loadChildren: './../modules/vr-search/vr-search.module#VrSearchModule'
  },
  {
    path: 'watch',
    loadChildren: './../modules/playlists/playlists.module#PlaylistsModule'
  },
  {
    path: 'notifications',
    loadChildren: './../modules/notifications/notifications.module#NotificationsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'live',
    loadChildren: './../modules/epg/epg.module#EpgModule'
  },
  {
    path: 'promotions',
    component: PaymentFlowComponent
  },
  {
    path: '**',
    redirectTo: ''
  }

];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class CoreRoutingModule { }
