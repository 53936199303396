import { Router } from '@angular/router';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';


@Component({
  selector: 'app-carousel-image',
  templateUrl: './carousel-image.component.html',
  styleUrls: ['./carousel-image.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselImageComponent implements OnInit {

  @Input() elements;
  isLoading = true;
  slideCurrent = 0;

  toPlayer(index: number = 0) {
    if(this.elements.elements[index].contentType == 'vod'){
      this.route.navigate(['/watch', 'public_live'], { queryParams: { l: 'streamings', t: this.elements.elements[index].link } });
    }else if(this.elements.elements[index].contentType == 'live'){
      this.route.navigate(['/live']);
    }else{
      this.route.navigate(['/watch', 'public_live'], { queryParams: { l: 'streamings', t: this.elements.elements[index].link } });
    }
  }

  constructor(public carouselOne: NguCarouselConfig, private route: Router) {

  }


  ngOnInit() {
    this.carouselOne = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 250,
      loop: true,
      velocity: 0.9,
      touch: true,
      point: {
        visible: true,
      },
      easing: 'cubic-bezier(0, 0, 0.2, 1)',
      //interval: { timing: 4000, initialDelay: 1000 }
    };

  }

  ngOnDestroy(): void {
    this.carouselOne = null;
  }

}
