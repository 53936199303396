import { GlobalsService } from "./../../../../services/globals.service";
import { AmplifyService } from "aws-amplify-angular";
import { AuthenticationService } from "../../../services/authentication.service";
import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoadingBarService } from "@ngx-loading-bar/core";
import {
  AuthService,
  FacebookLoginProvider,
  GoogleLoginProvider,
} from "angular-6-social-login-v2";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import Swal, { SweetAlertType } from "sweetalert2";
import { AUTH_VIEWS } from "../auth-form/auth-form.component";
import API from "./../../../../configs/ApisConfig";
import { HomeService } from "../../../services/home.service";

declare var dataLayer: any;

declare var AppleID;
enum Color {
  black = "black",
  light = "light",
}
enum Type {
  SignIn = "sign in",
  SignUp = "sign up",
  Apple = "apple",
  Continue = "continue",
}

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.css"],
  styles: [
    `
      .dark-modal .modal-content {
        background-color: #292b2c;
        color: white;
      }
      .dark-modal .close {
        color: white;
      }
    `,
  ],
})
export class SignInComponent implements OnInit {
  @Output()
  private changeView = new EventEmitter<number>();
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  closeResult: string;
  deviceInfo = null;
  /* TEMP VAR */
  emailModel: string;
  codeModel: string;
  nPasswordModel: string;
  userName: string; // for resend code
  typeDevice: string;
  os: string;
  device: string;

  errorRecover = 0;
  //com-videorolaapp-ios (RQWL9N6B4Z.com.videorolaapp.ios)
  scope: string = "name email";
  state: string = "123";
  clientId: string = "RQWL9N6B4Z.com.videorolaapp.ios";
  redirectUrl: string = "http://localhost:4200/login";

  constructor(
    private modalService: NgbModal,
    private authentication: AuthenticationService,
    private formBuilder: FormBuilder,
    private amplifyService: AmplifyService,
    private socialAutg: AuthService,
    private loadingBar: LoadingBarService,
    private global: GlobalsService,
    private home: HomeService
  ) {
    this.amplifyService = amplifyService;
    if (this.authentication.getCurrentUserInformation()) {
      this.global.fastRedirect();
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      emailLogin: ["", [Validators.required, Validators.email]],
      passwordLogin: ["", Validators.required],
    });

    this.os = "Chrome";
    this.device = "unknown";
    this.typeDevice = "Desktop";
  }

  get form() {
    return this.loginForm.controls;
  }

  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider: string;
    if (socialPlatform === "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAutg
      .signIn(socialPlatformProvider)
      .then((userData) => {
        this.completeSocialSignIn(userData);
      })
      .catch((e) => {
        this.showMessage(
          "Ups!",
          "No fue posible comunicarse con el proveedor de identidad, intentalo más tarde",
          "error"
        );
      });
  }

  public async signInApple() {
    try {
      AppleID.auth.init({
        clientId: "VRSignIn",
        scope: "name email",
        redirectURI: API.dev.apple_callback,
        state: "vr-init",
        nonce: "vr",
        usePopup: true,
      });
      const data = await AppleID.auth.signIn();
      const { sub, email } = this.authentication.parseJwt(
        data.authorization.id_token
      );
      this.completeSocialSignIn(
        { sub: sub, name: "", email: email, image: "", idToken: "" },
        "apple"
      );
    } catch (error) {
      console.log("Error in signin", error);
    }
  }

  private completeSocialSignIn(userData: any, provider?: string): void {
    const token = userData.idToken;
    const fullName = this.authentication.extractName(userData.name);
    let emailX = userData.email;
    const photo = userData.image;

    if (provider && provider == "apple") {
      //TODO: To extract data the different way
      emailX = userData.sub;
    }
    const t = this.authentication
      .getUserInformation(emailX, userData.email)
      .subscribe(
        (data) => {
          let userParam =
            provider && provider == "apple"
              ? data.user.user_id
              : data.user.email;

          let userTypeParam =
            provider && provider == "apple" ? provider : "VR-EMAIL";

          const ts = this.authentication
            .checkSession(userParam, userTypeParam)
            .subscribe(
              (res) => {
                if (res.data.result === true) {
                  // if there are
                  this.authentication.setCurrentProviderAuth("SL-A");

                  data.user.picture = photo;
                  data.name = fullName.name;
                  data.surnames = fullName.lastName;
                  this.authentication.updateUser(data.user);
                  this.authentication.setCurrentUserInformation(data.user);
                  this.global.redirect();
                  this.authentication.setCurrentUserInformation(data.user);

                  // Register session
                  const temp = this.authentication
                    .getSession(
                      data.user.user_id,
                      this.os,
                      this.device,
                      this.typeDevice
                    )
                    .subscribe(
                      (success) => {
                        //console.log(success.data.session);
                        dataLayer.push({ event: "evLogin" });
                        this.authentication.setCurrentSession({
                          id: success.data.session.session_id,
                          user: success.data.session.user,
                          os: success.data.session.os,
                          device: success.data.session.device,
                          type_device: success.data.session.type_device,
                          created_at: success.data.session.creation_date,
                        });
                        temp.unsubscribe();
                        setTimeout(() => {
                          this.home.getAccceptance();
                        }, 1200);
                      },
                      (error) => {
                        temp.unsubscribe();
                      }
                    );

                  /* */
                  this.authentication.registerInOneSignal(data.user.email);
                  /* */
                } else if (res.data.result === false) {
                  let inputOptions = {};
                  for (var i = 0; i < res.data.sessions.length; i++) {
                    inputOptions[
                      res.data.sessions[i].session_id +
                        "-" +
                        res.data.sessions[i].user
                    ] =
                      "Tipo:" +
                      res.data.sessions[i].device_type +
                      " Fecha:" +
                      res.data.sessions[i].creation_date.split(" ")[0] +
                      " Dispositivo:" +
                      res.data.sessions[i].device;
                  }
                  this.showMessageSessions("warning", inputOptions, false);
                }
                ts.unsubscribe();
              },
              (err) => {
                ts.unsubscribe();
                this.showMessage("Ups!", "", "error");
              }
            );
          t.unsubscribe();
        },
        (error) => {
          t.unsubscribe();
        }
      );
  }

  private Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    background: " rgb(30, 30, 30)",
    customClass: "vr-toast",
  });

  private handleErrors(e) {
    let message = "Actualmente no diponible, intentalo más tarde!";
    try {
      switch (e.code) {
        case "UserNotConfirmedException":
          message =
            "Es necesario que confirmes el correo que te enviamos a tu email cuando te registraste.";
          Swal.fire({
            type: "error",
            title: "Ups!",
            text: message,
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "Reenviar correo de confirmación",
            timer: 10000,
            background: "rgba(0,0,0,0.9)",
          }).then((res) => {
            if (res && res.value) {
              this.resendEmailVerification();
            }
          });

          return;
        case "NotAuthorizedException":
          message = "La contraseña es incorrecta";
          break;
        default:
          break;
      }
    } catch (error) {}

    Swal.fire({
      type: "error",
      title: "Ups!",
      text: message,
      showConfirmButton: true,
      timer: 10000,
      background: "rgba(0,0,0,0.9)",
    });
  }

  resendEmailVerification() {
    this.authentication.resendConfirmationEmail(this.userName).then(
      (e) => {
        this.Toast.fire({
          text: "Hemos enviado un nuevo correo a tu email",
          type: "success",
        });
      },
      (err) => {
        this.Toast.fire({
          text: "Hace poco enviamos un correo, intentalo más tarde",
          type: "error",
        });
      }
    );
  }

  open(content: any) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "dark-modal",
      })
      .result.then(
        (result) => {
          const codeString = this.codeModel;

          if (!codeString || !this.emailModel || !this.nPasswordModel) {
            this.errorRecover = 2;
            return;
          }

          this.errorRecover = 0;
          this.amplifyService
            .auth()
            .forgotPasswordSubmit(
              this.emailModel,
              codeString,
              this.nPasswordModel
            )
            .then((results) => {
              console.log(results);
              this.showMessage(
                "Ok!",
                "Inicie a VR con su nueva contraseña",
                "success"
              );
            })
            .catch((err) => {
              console.log(err);
              this.showMessage(
                "Ups!",
                "Ha ocurrido un errror, intenta de nuevo.",
                "error"
              );
            });
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  recover() {
    const codeString = this.codeModel;

    if (!codeString || !this.emailModel || !this.nPasswordModel) {
      this.errorRecover = 2;
      return;
    }

    this.errorRecover = 0;
    this.amplifyService
      .auth()
      .forgotPasswordSubmit(this.emailModel, codeString, this.nPasswordModel)
      .then((results) => {
        console.log(results);
        this.showMessage(
          "Ok!",
          "Inicie en VR con su nueva contraseña",
          "success"
        );
        this.modalService.dismissAll();
      })
      .catch((err) => {
        console.log(err);
        this.showMessage(
          "Ups!",
          "Ha ocurrido un errror, intenta de nuevo.",
          "error"
        );
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  sendCodeMail(mail: string) {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!mail.match(mailformat)) {
      this.errorRecover = 1;
      return false;
    }
    this.errorRecover = 0;
    this.authentication
      .forgotPassword(mail)
      .then((e) => {
        this.showMessage(
          "Ok!",
          "Revisa tu email, te hemos enviado un codigo!",
          "success"
        );
      })
      .catch((err) => {
        this.showMessage(
          "Ups!",
          "Ha ocurrido un error, posiblemente el email no existe!",
          "error"
        );
      });
  }

  showMessage(title: string, content: string, type: SweetAlertType) {
    Swal.fire({
      type: type,
      title: title,
      text: content,
      background: "rgba(0,0,0,0.9)",
      confirmButtonColor: "rgba(22,22,22,0.9)",
      timer: 3500,
    });
  }

  showMessageSessions(
    type: SweetAlertType,
    inputOptions: {},
    redirect = false
  ) {
    Swal.fire({
      type: type,
      title:
        "Ups! Has llegado al límite de dispositivos autorizados. Cierra sesión en alguno e inténtalo de nuevo en este.",
      input: "select",
      inputOptions: inputOptions,
      inputPlaceholder: "Dispositivos Activos",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          let keys = value.split("-");
          let session_id = keys[0];
          let user_id = keys[1];
          this.authentication
            .closeSelectedSession(user_id, session_id)
            .subscribe(
              (data) => {
                console.log(data);
                if (redirect) {
                  this.showMessage(
                    "Éxito",
                    "Iniciando sesión en este dispositivo.",
                    "success"
                  );
                  this.onSubmitLogin();
                } else {
                  this.showMessage(
                    "Éxito",
                    "Éxito ahora puedes iniciar sesión en este dispositivo.",
                    "success"
                  );
                }
              },
              (error) => {
                resolve(
                  "Ocurrió un problema al cerrar sesión de manera remota, favor de intentar de nuevo."
                );
              }
            );
        });
      },
      background: "rgba(0,0,0,0.9)",
      confirmButtonColor: "rgba(22,22,22,0.9)",
      confirmButtonText: "Seleccionar",
    });
  }

  public onSubmitLogin(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.showMessage(
        "Ups!",
        "Parece que alguno de tus datos no es correcto. Revisa e intenta de nuevo.",
        "warning"
      );
      return;
    } else {
      this.loading = true;
      this.loadingBar.start();
      const instance = this;
      this.userName = `${this.form.emailLogin.value}`;
      this.amplifyService
        .auth()
        .signIn(this.form.emailLogin.value, this.form.passwordLogin.value)
        .then((resu) => {
          instance.loadingBar.set(50);
          if (resu != null) {
            const checkSubscription = this.authentication
              .checkSession(this.form.emailLogin.value, "VR-EMAIL") // Check if there available new session
              .subscribe(
                (res) => {
                  if (res.data.result === true) {
                    // if there are
                    instance.loadingBar.complete();
                    this.authentication.setCurrentProviderAuth("VR-A");
                    // Update 1.x, get data session in this request
                    const tempSubs = this.authentication
                      .getUserInformation(this.form.emailLogin.value)
                      .subscribe(
                        (data) => {
                          this.authentication.setCurrentUserInformation(
                            data.user
                          );
                          setTimeout(() => {
                            this.home.getAccceptance();
                          }, 1500);
                          const t = this.authentication
                            .getSession(
                              data.user.user_id,
                              this.os,
                              this.device,
                              this.typeDevice
                            )
                            .subscribe(
                              (success) => {
                                this.authentication.setCurrentSession({
                                  id: success.data.session.session_id,
                                  user: success.data.session.user,
                                  os: success.data.session.os,
                                  device: success.data.session.device,
                                  type_device: success.data.session.type_device,
                                  created_at:
                                    success.data.session.creation_date,
                                });
                                t.unsubscribe();
                              },
                              (error) => {
                                t.unsubscribe();
                              }
                            );

                          this.authentication.registerInOneSignal(
                            data.user.email
                          );

                          tempSubs.unsubscribe();
                        },
                        (error) => {
                          tempSubs.unsubscribe();
                        }
                      );
                    this.global.redirect();
                    this.amplifyService.setAuthState({
                      state: "signedIn",
                      user: resu,
                    });
                  } else if (res.data.result === false) {
                    //this.authentication.logOut();
                    let inputOptions = {};
                    for (var i = 0; i < res.data.sessions.length; i++) {
                      inputOptions[
                        res.data.sessions[i].session_id +
                          "-" +
                          res.data.sessions[i].user
                      ] =
                        "Tipo:" +
                        res.data.sessions[i].device_type +
                        " Fecha:" +
                        res.data.sessions[i].creation_date.split(" ")[0] +
                        " Dispositivo:" +
                        res.data.sessions[i].device;
                    }
                    this.showMessageSessions("warning", inputOptions, true);
                    instance.loadingBar.complete();
                  }
                  checkSubscription.unsubscribe();
                },
                (err) => {
                  this.showMessage(
                    "Ups el usuario ingresado no existe",
                    "",
                    "error"
                  );
                  instance.loadingBar.complete();
                }
              );
          } else {
            this.showMessage(
              "Usuario/Contraseña incorrectos!",
              "Parece que alguno de tus datos no es correcto. Revisa e intenta de nuevo.",
              "error"
            );
            instance.loadingBar.complete();
          }
          return resu;
        })
        .catch((e) => {
          this.handleErrors(e);
          instance.loadingBar.complete();
        });
    }
  }

  public showSignUpView(): void {
    this.changeView.emit(AUTH_VIEWS.SIGN_UP);
  }

  public showProviderView(): void {
    this.changeView.emit(AUTH_VIEWS.PROVIDER_SIGN_IN);
  }
}
