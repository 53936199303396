import { Router } from "@angular/router";
import { AuthenticationService } from "../../services/authentication.service";
import { HomeService } from "./../../services/home.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { GlobalsService } from "./../../../services/globals.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  mainAd = "";
  imgAd = "";
  urlAd = "";
  counter = 0;
  interval: any;

  constructor(
    public homeService: HomeService,
    private gService: GlobalsService,
    private auth: AuthenticationService,
    private router: Router
  ) {
    this.homeService.refreshElements();
  }

  ngOnInit() {
    this.gService.getCurrentLocation();
    //if (this.auth.getPreferences().isShowMainAd) {
      this.subscription = this.gService.getAdvertising().subscribe((e) => {
        this.counter = e.advertising.waitTime;
        if (e.advertising.status) {
          this.mainAd = "d-block";
          this.imgAd = e.advertising.imageUrl;
          this.urlAd = e.advertising.link;
          this.interval = setInterval(() => {
            this.counter--;
          }, 1000);
          setTimeout(() => {
            this.hideAdvertising();
          }, e.advertising.waitTime * 1000);
        }
      });
    //}
    this.homeService.getAccceptance();
  }

  hideAdvertising(id: string = ""): void {
    this.mainAd = "";
    if (id) {
      this.addClick(id);
    }
    clearInterval(this.interval);
  }

  addClick(id) {
    const subs = this.homeService.addClick(id).subscribe(
      (data) => {
        subs.unsubscribe();
      },
      (e) => {
        subs.unsubscribe();
      }
    );
  }

  gotoGrid() {
    this.router.navigate(["watch/play-lists", "related-videos"]);
  }

  openAdvertising(url: string) {
    const win = window.open(url, "_blank");
    win.focus();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
