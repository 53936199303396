import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-viewer",
  templateUrl: "./viewer.component.html",
  styleUrls: ["./viewer.component.css"],
})
export class ViewerComponent implements OnInit {
  title: string = "";
  content = "";

  constructor(private active: NgbActiveModal) {}

  ngOnInit() {}

  onAccept() {
    this.active.close(true);
  }
}
