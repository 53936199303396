import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NguCarouselModule, NguCarouselConfig, NguCarousel } from '@ngu/carousel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from './../services/globals.service';
import { AdvertisingComponent } from './components/advertising/advertising.component';
import { CarouselVideoComponent } from './components/carousel-video/carousel-video.component';
import { CarouselPlaylistComponent } from './components/carousel-playlist/carousel-playlist.component';
import { CarouselImageComponent } from './components/carousel-image/carousel-image.component';
import { CoverPageMediaComponent } from './components/cover-page-media/cover-page-media.component';
import { MediaGridComponent } from './components/media-grid/media-grid.component';
//import { CommentBoxComponent } from './components/comment-box/comment-box.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ContextMenuModule } from 'ngx-contextmenu';
import {
  PaymentFlowComponent,
  PromotionSelectorComponent, OxxoPaymentMethodComponent, CardPaymentMethodComponent
} from './components/payment-flow/payment-flow.component';
import { PaymentService } from '../services';
import { CarouselSearchComponent } from './components/carousel-search/carousel-search.component';


@NgModule({
  declarations: [
    CarouselVideoComponent,
    CarouselPlaylistComponent,
    CarouselImageComponent,
    CoverPageMediaComponent,
    MediaGridComponent,
    //CommentBoxComponent,
    LoaderComponent,
    AdvertisingComponent,
    PaymentFlowComponent,
    PromotionSelectorComponent,
    OxxoPaymentMethodComponent,
    CardPaymentMethodComponent,
    CarouselSearchComponent
  ],
  imports: [
    CommonModule,
    NguCarouselModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    ContextMenuModule.forRoot(),
  ],
  providers: [
    NguCarouselConfig,
    NguCarousel,
    GlobalsService,
    PaymentService
  ],
  exports: [
    CarouselVideoComponent,
    CarouselPlaylistComponent,
    CarouselImageComponent,
    CoverPageMediaComponent,
    MediaGridComponent,
   // CommentBoxComponent,
    LoaderComponent,
    AdvertisingComponent,
    PromotionSelectorComponent,
    OxxoPaymentMethodComponent,
    CardPaymentMethodComponent,
    CarouselSearchComponent
  ],
  entryComponents: [
    PromotionSelectorComponent,
    OxxoPaymentMethodComponent,
    CardPaymentMethodComponent
  ]
})
export class SharedModule { }
