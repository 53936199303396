import { Router } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';
import { PlayListControlService } from '../../../../services/play-list-control.service';
import { IPlayList } from '../../../../models/IPlayList';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent implements OnDestroy {





  constructor(
    public router: Router,
    public play: PlayListControlService
  ) {


  }


  playSection(data: {playList: IPlayList, current: string}){
    this.play.currentPlayList = data.playList;
    this.router.navigate(['/watch', 'public_search'], { queryParams: { l: 'related', t:  data.current } });
  }

  /**
   * @deprecated
   */
  toPlayer(i: number) {
    this.play.currentPlayList = this.play.searchPlayListResult;
    this.router.navigate(['/watch', 'public_search'], { queryParams: { l: 'related', t:  this.play.currentPlayList.tracks[i].track_id } });
  }

  ngOnDestroy(): void {
    //this.play.clearSearch();
  }

}
